import { Switch, Redirect } from "react-router-dom";
import { ROUTES } from "utils/constants";
import TableView from "./table-view";
import MapView from "./map-view";
import VehicleDetail from "./vehicle-detail";
import ProtectedRoute from "components/protected-route";
import ModelTableView from "../vehicle-models-content/table-view";
import {useSelector} from "react-redux";
import {AppState} from "../../../store";
export default function VehiclesContent() {
  const {
    common: { userType = 1 },
  } = useSelector<AppState, AppState>((state) => state);
  let defaultRoute = userType == 5 ? ROUTES.vehicles_map : ROUTES.vehicles_list;
  return (
    <div className="vehicles-content">
      <Switch>
        <ProtectedRoute exact path={ROUTES.vehicles}>
          <Redirect to={defaultRoute} />
        </ProtectedRoute>
        <ProtectedRoute exact path={ROUTES.vehicles_list}>
          <TableView />
        </ProtectedRoute>
        <ProtectedRoute exact path={ROUTES.vehicle_models_list}>
          <ModelTableView />
        </ProtectedRoute>
        <ProtectedRoute exact path={ROUTES.vehicles_map}>
          <MapView />
        </ProtectedRoute>
        <ProtectedRoute exact path={ROUTES.vehicle_detail}>
          <VehicleDetail />
        </ProtectedRoute>
        <ProtectedRoute path={`${ROUTES.vehicles}/*`}>
          <Redirect to={ROUTES.vehicles_map} />
        </ProtectedRoute>
      </Switch>
    </div>
  );
}
