import {
    InnerLayout,
    ZonesContent,
    ZonesFilter,
    ZonesHeader,
} from "components";

import React, {useCallback, useRef, useState} from 'react';
import { Autocomplete, DrawingManager, GoogleMap, Polygon, useJsApiLoader } from '@react-google-maps/api';
import deleteIcon from '../../assets/images/remove.png';
import {MAP_API_KEY} from "../../utils/constants";
import {breadcrumbIcon, plusIcon} from "../../svgs";
import {Breadcrumb, Button} from "antd";
import {setCoordinates, setModalName} from "../../store/actions";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";

const libraries:any = ['places', 'drawing'];

export default function AddZoneForm() {
    const dispatch = useDispatch();
    const mapRef:any = useRef();
    const polygonRefs:any = useRef([]);
    const activePolygonIndex:any = useRef();
    const autocompleteRef:any = useRef();
    const drawingManagerRef:any = useRef();

    const history = useHistory();
    const onClickBack = useCallback(() => {
        history.goBack();
    }, [history]);

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: MAP_API_KEY,
        libraries
    });

    const [polygons, setPolygons] = useState([[]]);

    const defaultCenter:any = {
        lat: 31.582045,
        lng: 74.329376,
    }
    const [center, setCenter] = useState(defaultCenter);

    const containerStyle:any = {
        width: '100%',
        height: '400px',
    }

    const autocompleteStyle:any = {
        boxSizing: 'border-box',
        border: '1px solid transparent',
        width: '240px',
        height: '38px',
        padding: '0 12px',
        borderRadius: '3px',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
        fontSize: '14px',
        outline: 'none',
        textOverflow: 'ellipses',
        position: 'absolute',
        right: '8%',
        top: '11px',
        marginLeft: '-120px',
    }

    const deleteIconStyle:any = {
        cursor: 'pointer',
        backgroundImage: `url(${deleteIcon})`,
        height: '24px',
        width: '24px',
        marginTop: '3px',
        backgroundColor: '#fff',
        position: 'absolute',
        top: "2px",
        left: "52%",
        zIndex: 1
    }

    const polygonOptions = {
        fillOpacity: 0.3,
        fillColor: '#ff0000',
        strokeColor: '#ff0000',
        strokeWeight: 2,
        draggable: true,
        editable: true
    }

    const drawingManagerOptions = {
        polygonOptions: polygonOptions,
        drawingControl: true,
        drawingControlOptions: {
            position: window.google?.maps?.ControlPosition?.TOP_CENTER,
            drawingModes: [
                window.google?.maps?.drawing?.OverlayType?.POLYGON
            ]
        }
    }

    const onLoadMap = (map: any) => {
        mapRef.current = map;
    }

    const onLoadPolygon = (polygon:any, index:any) => {
        polygonRefs.current[index] = polygon;
    }

    const onClickPolygon = (index:any) => {
        activePolygonIndex.current = index;
    }

    const onLoadAutocomplete = (autocomplete:any) => {
        autocompleteRef.current = autocomplete;
    }

    const onPlaceChanged = () => {
        const { geometry } = autocompleteRef.current.getPlace();
        const bounds = new window.google.maps.LatLngBounds();
        if (geometry.viewport) {
            bounds.union(geometry.viewport);
        } else {
            bounds.extend(geometry.location);
        }
        mapRef.current.fitBounds(bounds);
    }

    const onLoadDrawingManager:any = (drawingManager:any) => {
        drawingManagerRef.current = drawingManager;
    }

    const onOverlayComplete:any = ($overlayEvent:any) => {
        drawingManagerRef.current.setDrawingMode(null);
        if ($overlayEvent.type === window.google.maps.drawing.OverlayType.POLYGON) {
            const newPolygon = $overlayEvent.overlay.getPath()
                .getArray()
                .map((latLng: { lat: () => number; lng: () => number; }) => ({ lat: latLng.lat(), lng: latLng.lng() }))

            // start and end point should be same for valid geojson
            const startPoint = newPolygon[0];
            newPolygon.push(startPoint);
            $overlayEvent.overlay?.setMap(null);
            setPolygons([...polygons, newPolygon]);
            console.log("polygons : ", polygons)
            console.log("new Polygons: ", newPolygon)
        }
    }

    const handlePolygonComplete = (polygon: { getPath: () => any; }) => {
        const path = polygon.getPath();
        const coordinates = path.getArray().map((latLng: { lat: () => any; lng: () => any; }) => ({
            lat: latLng.lat(),
            lng: latLng.lng(),
        }));
        console.log("coordinates : ", coordinates);
    };

    const onDeleteDrawing = () => {
        const filtered = polygons.filter((polygon, index) => index !== activePolygonIndex.current)
        setPolygons(filtered)
    }

    const onEditPolygon = (index: number) => {
        const polygonRef = polygonRefs.current[index];
        if (polygonRef) {
            const coordinates = polygonRef.getPath()
                .getArray()
                .map((latLng: { lat: () => any; lng: () => any; }) => ({ lat: latLng.lat(), lng: latLng.lng() }));

            const allPolygons = [...polygons];
            allPolygons[index] = coordinates;
            setPolygons(allPolygons)
            console.log("Edit Cordinates : ", coordinates)
        }
    }

    const addZone = () => {
        const coordinates = polygons.filter(p => {
            return p.length > 0
        })
        if (coordinates.length < 1) {
            return window.alert("Please draw polygon first!");
        }
        let zoneCoordinates:[] = coordinates[0] as []
        console.log("zoneCoordinates : ", zoneCoordinates)
        dispatch(setCoordinates(zoneCoordinates))
        dispatch(setModalName("add_zone_modal"));
    }

    return (
        isLoaded
            ?<>
                <section className="__table-container" style={{ marginTop: "20px"}}>
                    <div style={{ width: "50px" }}></div>
                    <Breadcrumb className="vehicle-route" separator={breadcrumbIcon} style={{ marginLeft: "-15px"}}>
                        <Breadcrumb.Item>
                            <Button type="link" className="vehicles-link" onClick={onClickBack}>
                                Zone
                            </Button>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Add New Zone</Breadcrumb.Item>
                    </Breadcrumb>
                </section>
                <div style={{marginBottom : '10px'}}>
                    <p>Please draw a polyline to create a geozone and then click on 'Add Zone' button..</p>
                    <div>
                        <span style={{display: 'inline-block'}}>
                            <div style={{width: '16px', height: '16px', overflow: 'hidden', position: 'relative'}}>
                                <img alt="" src="https://maps.gstatic.com/mapfiles/drawing.png" draggable="false" style={{position: 'absolute', left: '0px', top: '-64px', userSelect: 'none', border: '0px;', padding: '0px',margin: '0px', maxWidth: 'none', width: '16px', height: '192px'}} />
                                </div>
                        </span>
                        <span> Click on this icon on the map to a geozone polygon.</span>
                    </div>
                    <div>
                        <span style={{display: 'inline-block'}}>
                            <div style={{width: '16px', height: '16px', overflow: 'hidden', position: 'relative'}}>
                                <img alt="" src="https://maps.gstatic.com/mapfiles/drawing.png" draggable="false" style={{position: 'absolute', left: '0px', top: '-144px', userSelect: 'none', border: '0px;', padding: '0px',margin: '0px', maxWidth: 'none', width: '16px', height: '192px'}} />
                                </div>
                        </span>
                        <span> Click on this icon on the map to move position on the map.  </span>
                    </div>
                </div>
            <div className='map-container' style={{ position: 'relative' }}>

                {
                    drawingManagerRef.current
                    &&
                    <div
                        onClick={onDeleteDrawing}
                        title='Delete shape'
                        style={deleteIconStyle}>
                    </div>
                }
                <GoogleMap
                    zoom={15}
                    center={center}
                    onLoad={onLoadMap}
                    mapContainerStyle={containerStyle}
                    onTilesLoaded={() => setCenter(null)}
                >
                    <DrawingManager
                        onLoad={onLoadDrawingManager}
                        onOverlayComplete={onOverlayComplete}
                        onPolygonComplete={handlePolygonComplete}
                        options={drawingManagerOptions}
                    />
                    {
                        polygons.map((iterator, index) => (
                            <Polygon
                                key={index}
                                onLoad={(event) => onLoadPolygon(event, index)}
                                onMouseDown={() => onClickPolygon(index)}
                                onMouseUp={() => onEditPolygon(index)}
                                onDragEnd={() => onEditPolygon(index)}
                                options={polygonOptions}
                                paths={iterator}
                                draggable
                                editable
                            />
                        ))
                    }
                    <Autocomplete
                        onLoad={onLoadAutocomplete}
                        onPlaceChanged={onPlaceChanged}
                    >
                        <input
                            type='text'
                            placeholder='Search Location'
                            style={autocompleteStyle}
                        />
                    </Autocomplete>
                </GoogleMap>
            </div>
            <div style={{textAlign: "end"}}>
                <Button
                    type="primary"
                 //   icon={<span className="custom-icon">{plusIcon}</span>}
                    onClick={addZone}
                    style={{marginTop: "15px"}}
                >
                  
                    Save
                </Button>
            </div>
            </>
            :
            null
    );
}
