import { useEffect, useMemo } from "react";
import { Map } from "components";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import { parseVehicleMaps } from "utils/parsers";
import { initializeVehicles } from "store/actions";
import { useInterval } from "utils/hooks";
import { POLLING_MSECS } from "utils/constants";
import {useCookies} from "react-cookie";
import {markerObject} from "../../../types";

const getFormatedDate = (unixTime:number) => {
  const inputDate = new Date(unixTime * 1000);

  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  return inputDate.toLocaleString('en-US', options);
}

export default function MapView() {
  const dispatch = useDispatch();
  const {
    vehicles,
    filters: { updated },
    page: { selectedPage },
  } = useSelector<AppState, AppState["vehicles"]>((state) => state.vehicles);
  const {
    map_marker,
    is_map_marker_change,
  } = useSelector<AppState, AppState["map_marker"]>((state) => state.map_marker);
  const [cookies] = useCookies()
  const parsedVehicles = useMemo(() => parseVehicleMaps(vehicles), [vehicles]);
  const markersPoint = useMemo(() => {
    return parsedVehicles.map((vehicle) => ({
      id: vehicle.id,
      lat: vehicle.point?.latitude || 0,
      lng: vehicle.point?.longitude || 0,
      licence_plate: vehicle?.licensePlate || "",
      updated_time: getFormatedDate(vehicle.locationUpdatedAt || 0),
      functional_status: vehicle.vehicleFunctionalStatus,
      checked_out_name: vehicle.checkedOutByName,
    }));
    //return [{id:"123", lat:31.501660, lng:74.361969}]
  }, [parsedVehicles]);
  useInterval(() => {
    dispatch(initializeVehicles(selectedPage,
        updated, true, false));
    console.log("check interva");
  }, POLLING_MSECS);

  useEffect(() => {
    dispatch(initializeVehicles(selectedPage, updated, true));
  }, [dispatch, selectedPage, updated]);
  return <Map markersPoint={markersPoint} center={map_marker} />;
}
