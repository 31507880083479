/**
 * @fileoverview gRPC-Web generated client stub for api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v4.24.2
// source: authc_service.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as authc_service_pb from './authc_service_pb';


export class AuthcServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAuthenticatePassword = new grpcWeb.MethodDescriptor(
    '/api.AuthcService/AuthenticatePassword',
    grpcWeb.MethodType.UNARY,
    authc_service_pb.PasswordAuthcRequest,
    authc_service_pb.AuthenticationResponse,
    (request: authc_service_pb.PasswordAuthcRequest) => {
      return request.serializeBinary();
    },
    authc_service_pb.AuthenticationResponse.deserializeBinary
  );

  authenticatePassword(
    request: authc_service_pb.PasswordAuthcRequest,
    metadata: grpcWeb.Metadata | null): Promise<authc_service_pb.AuthenticationResponse>;

  authenticatePassword(
    request: authc_service_pb.PasswordAuthcRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: authc_service_pb.AuthenticationResponse) => void): grpcWeb.ClientReadableStream<authc_service_pb.AuthenticationResponse>;

  authenticatePassword(
    request: authc_service_pb.PasswordAuthcRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: authc_service_pb.AuthenticationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.AuthcService/AuthenticatePassword',
        request,
        metadata || {},
        this.methodDescriptorAuthenticatePassword,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.AuthcService/AuthenticatePassword',
    request,
    metadata || {},
    this.methodDescriptorAuthenticatePassword);
  }

  methodDescriptorRefreshToken = new grpcWeb.MethodDescriptor(
    '/api.AuthcService/RefreshToken',
    grpcWeb.MethodType.UNARY,
    authc_service_pb.RefreshRequest,
    authc_service_pb.RefreshResponse,
    (request: authc_service_pb.RefreshRequest) => {
      return request.serializeBinary();
    },
    authc_service_pb.RefreshResponse.deserializeBinary
  );

  refreshToken(
    request: authc_service_pb.RefreshRequest,
    metadata: grpcWeb.Metadata | null): Promise<authc_service_pb.RefreshResponse>;

  refreshToken(
    request: authc_service_pb.RefreshRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: authc_service_pb.RefreshResponse) => void): grpcWeb.ClientReadableStream<authc_service_pb.RefreshResponse>;

  refreshToken(
    request: authc_service_pb.RefreshRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: authc_service_pb.RefreshResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.AuthcService/RefreshToken',
        request,
        metadata || {},
        this.methodDescriptorRefreshToken,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.AuthcService/RefreshToken',
    request,
    metadata || {},
    this.methodDescriptorRefreshToken);
  }

  methodDescriptorPhoneLogin = new grpcWeb.MethodDescriptor(
    '/api.AuthcService/PhoneLogin',
    grpcWeb.MethodType.UNARY,
    authc_service_pb.PhoneLoginRequest,
    authc_service_pb.PhoneLoginResponse,
    (request: authc_service_pb.PhoneLoginRequest) => {
      return request.serializeBinary();
    },
    authc_service_pb.PhoneLoginResponse.deserializeBinary
  );

  phoneLogin(
    request: authc_service_pb.PhoneLoginRequest,
    metadata: grpcWeb.Metadata | null): Promise<authc_service_pb.PhoneLoginResponse>;

  phoneLogin(
    request: authc_service_pb.PhoneLoginRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: authc_service_pb.PhoneLoginResponse) => void): grpcWeb.ClientReadableStream<authc_service_pb.PhoneLoginResponse>;

  phoneLogin(
    request: authc_service_pb.PhoneLoginRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: authc_service_pb.PhoneLoginResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.AuthcService/PhoneLogin',
        request,
        metadata || {},
        this.methodDescriptorPhoneLogin,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.AuthcService/PhoneLogin',
    request,
    metadata || {},
    this.methodDescriptorPhoneLogin);
  }

  methodDescriptorStartPhoneVerification = new grpcWeb.MethodDescriptor(
    '/api.AuthcService/StartPhoneVerification',
    grpcWeb.MethodType.UNARY,
    authc_service_pb.StartPhoneSignupRequest,
    authc_service_pb.StartPhoneSignupResponse,
    (request: authc_service_pb.StartPhoneSignupRequest) => {
      return request.serializeBinary();
    },
    authc_service_pb.StartPhoneSignupResponse.deserializeBinary
  );

  startPhoneVerification(
    request: authc_service_pb.StartPhoneSignupRequest,
    metadata: grpcWeb.Metadata | null): Promise<authc_service_pb.StartPhoneSignupResponse>;

  startPhoneVerification(
    request: authc_service_pb.StartPhoneSignupRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: authc_service_pb.StartPhoneSignupResponse) => void): grpcWeb.ClientReadableStream<authc_service_pb.StartPhoneSignupResponse>;

  startPhoneVerification(
    request: authc_service_pb.StartPhoneSignupRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: authc_service_pb.StartPhoneSignupResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.AuthcService/StartPhoneVerification',
        request,
        metadata || {},
        this.methodDescriptorStartPhoneVerification,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.AuthcService/StartPhoneVerification',
    request,
    metadata || {},
    this.methodDescriptorStartPhoneVerification);
  }

  methodDescriptorAddShakeHandsRecord = new grpcWeb.MethodDescriptor(
    '/api.AuthcService/AddShakeHandsRecord',
    grpcWeb.MethodType.UNARY,
    authc_service_pb.AddShakeHandsRequest,
    authc_service_pb.AddShakeHandsResponse,
    (request: authc_service_pb.AddShakeHandsRequest) => {
      return request.serializeBinary();
    },
    authc_service_pb.AddShakeHandsResponse.deserializeBinary
  );

  addShakeHandsRecord(
    request: authc_service_pb.AddShakeHandsRequest,
    metadata: grpcWeb.Metadata | null): Promise<authc_service_pb.AddShakeHandsResponse>;

  addShakeHandsRecord(
    request: authc_service_pb.AddShakeHandsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: authc_service_pb.AddShakeHandsResponse) => void): grpcWeb.ClientReadableStream<authc_service_pb.AddShakeHandsResponse>;

  addShakeHandsRecord(
    request: authc_service_pb.AddShakeHandsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: authc_service_pb.AddShakeHandsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.AuthcService/AddShakeHandsRecord',
        request,
        metadata || {},
        this.methodDescriptorAddShakeHandsRecord,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.AuthcService/AddShakeHandsRecord',
    request,
    metadata || {},
    this.methodDescriptorAddShakeHandsRecord);
  }

}

