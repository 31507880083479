// source: issue_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var page_pb = require('./page_pb.js');
goog.object.extend(proto, page_pb);
var filter_pb = require('./filter_pb.js');
goog.object.extend(proto, filter_pb);
goog.exportSymbol('proto.api.AddIssueManagementRequest', null, global);
goog.exportSymbol('proto.api.AddIssueManagementResponse', null, global);
goog.exportSymbol('proto.api.AddIssueRequest', null, global);
goog.exportSymbol('proto.api.AddIssueResponse', null, global);
goog.exportSymbol('proto.api.ConsigneeItem', null, global);
goog.exportSymbol('proto.api.CountIssuesByStatusManagementRequest', null, global);
goog.exportSymbol('proto.api.CountIssuesByStatusManagementResponse', null, global);
goog.exportSymbol('proto.api.CountIssuesByTypeManagementRequest', null, global);
goog.exportSymbol('proto.api.CountIssuesByTypeManagementResponse', null, global);
goog.exportSymbol('proto.api.CountIssuesWeeklyManagementRequest', null, global);
goog.exportSymbol('proto.api.CountIssuesWeeklyManagementResponse', null, global);
goog.exportSymbol('proto.api.DeleteIssueManagementRequest', null, global);
goog.exportSymbol('proto.api.DeleteIssueManagementResponse', null, global);
goog.exportSymbol('proto.api.GetIssueManagementResponse', null, global);
goog.exportSymbol('proto.api.GetIssueManagementResponse.Item', null, global);
goog.exportSymbol('proto.api.GetMyCreatedIssuesResponse', null, global);
goog.exportSymbol('proto.api.GetMyIssuesRequest', null, global);
goog.exportSymbol('proto.api.GetMyIssuesResponse', null, global);
goog.exportSymbol('proto.api.IssueItem', null, global);
goog.exportSymbol('proto.api.IssueItemListing', null, global);
goog.exportSymbol('proto.api.IssueManagementUpdateItem', null, global);
goog.exportSymbol('proto.api.IssueStatus', null, global);
goog.exportSymbol('proto.api.IssueStatus.Enum', null, global);
goog.exportSymbol('proto.api.IssueType', null, global);
goog.exportSymbol('proto.api.IssueType.Enum', null, global);
goog.exportSymbol('proto.api.IssueUnresolved', null, global);
goog.exportSymbol('proto.api.IssueUpdateItem', null, global);
goog.exportSymbol('proto.api.IssuesByType', null, global);
goog.exportSymbol('proto.api.KioskIssueType', null, global);
goog.exportSymbol('proto.api.KioskIssueType.Enum', null, global);
goog.exportSymbol('proto.api.ListIssuesAdminRequest', null, global);
goog.exportSymbol('proto.api.ListIssuesAdminRequest.Filter', null, global);
goog.exportSymbol('proto.api.ListIssuesAdminResponse', null, global);
goog.exportSymbol('proto.api.ListIssuesAdminResponse.Item', null, global);
goog.exportSymbol('proto.api.ListIssuesManagementRequest', null, global);
goog.exportSymbol('proto.api.ListIssuesManagementRequest.Filter', null, global);
goog.exportSymbol('proto.api.ListIssuesManagementResponse', null, global);
goog.exportSymbol('proto.api.ListIssuesManagementResponse.Item', null, global);
goog.exportSymbol('proto.api.ListUnresolvedDashboardManagementRequest', null, global);
goog.exportSymbol('proto.api.ListUnresolvedDashboardManagementResponse', null, global);
goog.exportSymbol('proto.api.ListUnresolvedDashboardManagementResponse.Item', null, global);
goog.exportSymbol('proto.api.ListUsersConsigneeManagementRequest', null, global);
goog.exportSymbol('proto.api.ListUsersConsigneeManagementResponse', null, global);
goog.exportSymbol('proto.api.ListUsersConsigneeManagementResponse.Item', null, global);
goog.exportSymbol('proto.api.UndoDeleteIssueManagementRequest', null, global);
goog.exportSymbol('proto.api.UndoDeleteIssueManagementResponse', null, global);
goog.exportSymbol('proto.api.UpdateIssueManagmentRequest', null, global);
goog.exportSymbol('proto.api.UpdateIssueManagmentResponse', null, global);
goog.exportSymbol('proto.api.UpdateIssueRequest', null, global);
goog.exportSymbol('proto.api.UpdateIssueResponse', null, global);
goog.exportSymbol('proto.api.duration', null, global);
goog.exportSymbol('proto.api.duration.Enum', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetMyIssuesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetMyIssuesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetMyIssuesRequest.displayName = 'proto.api.GetMyIssuesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.IssueStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.IssueStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.IssueStatus.displayName = 'proto.api.IssueStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.IssueType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.IssueType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.IssueType.displayName = 'proto.api.IssueType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.KioskIssueType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.KioskIssueType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.KioskIssueType.displayName = 'proto.api.KioskIssueType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.IssueItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.IssueItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.IssueItem.displayName = 'proto.api.IssueItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.IssueItemListing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.IssueItemListing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.IssueItemListing.displayName = 'proto.api.IssueItemListing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.IssueUpdateItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.IssueUpdateItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.IssueUpdateItem.displayName = 'proto.api.IssueUpdateItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListIssuesManagementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ListIssuesManagementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListIssuesManagementRequest.displayName = 'proto.api.ListIssuesManagementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListIssuesManagementRequest.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ListIssuesManagementRequest.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListIssuesManagementRequest.Filter.displayName = 'proto.api.ListIssuesManagementRequest.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListIssuesAdminRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ListIssuesAdminRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListIssuesAdminRequest.displayName = 'proto.api.ListIssuesAdminRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListIssuesAdminRequest.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ListIssuesAdminRequest.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListIssuesAdminRequest.Filter.displayName = 'proto.api.ListIssuesAdminRequest.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListIssuesManagementResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.ListIssuesManagementResponse.repeatedFields_, null);
};
goog.inherits(proto.api.ListIssuesManagementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListIssuesManagementResponse.displayName = 'proto.api.ListIssuesManagementResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListIssuesManagementResponse.Item = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ListIssuesManagementResponse.Item, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListIssuesManagementResponse.Item.displayName = 'proto.api.ListIssuesManagementResponse.Item';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListIssuesAdminResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.ListIssuesAdminResponse.repeatedFields_, null);
};
goog.inherits(proto.api.ListIssuesAdminResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListIssuesAdminResponse.displayName = 'proto.api.ListIssuesAdminResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListIssuesAdminResponse.Item = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ListIssuesAdminResponse.Item, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListIssuesAdminResponse.Item.displayName = 'proto.api.ListIssuesAdminResponse.Item';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateIssueRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateIssueRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateIssueRequest.displayName = 'proto.api.UpdateIssueRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateIssueResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateIssueResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateIssueResponse.displayName = 'proto.api.UpdateIssueResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateIssueManagmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateIssueManagmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateIssueManagmentRequest.displayName = 'proto.api.UpdateIssueManagmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateIssueManagmentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateIssueManagmentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateIssueManagmentResponse.displayName = 'proto.api.UpdateIssueManagmentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.IssueManagementUpdateItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.IssueManagementUpdateItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.IssueManagementUpdateItem.displayName = 'proto.api.IssueManagementUpdateItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AddIssueRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.AddIssueRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AddIssueRequest.displayName = 'proto.api.AddIssueRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AddIssueManagementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.AddIssueManagementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AddIssueManagementRequest.displayName = 'proto.api.AddIssueManagementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetIssueManagementResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetIssueManagementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetIssueManagementResponse.displayName = 'proto.api.GetIssueManagementResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetIssueManagementResponse.Item = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetIssueManagementResponse.Item, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetIssueManagementResponse.Item.displayName = 'proto.api.GetIssueManagementResponse.Item';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AddIssueResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.AddIssueResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AddIssueResponse.displayName = 'proto.api.AddIssueResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AddIssueManagementResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.AddIssueManagementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AddIssueManagementResponse.displayName = 'proto.api.AddIssueManagementResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetMyIssuesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.GetMyIssuesResponse.repeatedFields_, null);
};
goog.inherits(proto.api.GetMyIssuesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetMyIssuesResponse.displayName = 'proto.api.GetMyIssuesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetMyCreatedIssuesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.GetMyCreatedIssuesResponse.repeatedFields_, null);
};
goog.inherits(proto.api.GetMyCreatedIssuesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetMyCreatedIssuesResponse.displayName = 'proto.api.GetMyCreatedIssuesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.DeleteIssueManagementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.DeleteIssueManagementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.DeleteIssueManagementRequest.displayName = 'proto.api.DeleteIssueManagementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.DeleteIssueManagementResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.DeleteIssueManagementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.DeleteIssueManagementResponse.displayName = 'proto.api.DeleteIssueManagementResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UndoDeleteIssueManagementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UndoDeleteIssueManagementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UndoDeleteIssueManagementRequest.displayName = 'proto.api.UndoDeleteIssueManagementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UndoDeleteIssueManagementResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UndoDeleteIssueManagementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UndoDeleteIssueManagementResponse.displayName = 'proto.api.UndoDeleteIssueManagementResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ConsigneeItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ConsigneeItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ConsigneeItem.displayName = 'proto.api.ConsigneeItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListUsersConsigneeManagementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ListUsersConsigneeManagementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListUsersConsigneeManagementRequest.displayName = 'proto.api.ListUsersConsigneeManagementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListUsersConsigneeManagementResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.ListUsersConsigneeManagementResponse.repeatedFields_, null);
};
goog.inherits(proto.api.ListUsersConsigneeManagementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListUsersConsigneeManagementResponse.displayName = 'proto.api.ListUsersConsigneeManagementResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListUsersConsigneeManagementResponse.Item = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ListUsersConsigneeManagementResponse.Item, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListUsersConsigneeManagementResponse.Item.displayName = 'proto.api.ListUsersConsigneeManagementResponse.Item';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.IssueUnresolved = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.IssueUnresolved, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.IssueUnresolved.displayName = 'proto.api.IssueUnresolved';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListUnresolvedDashboardManagementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ListUnresolvedDashboardManagementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListUnresolvedDashboardManagementRequest.displayName = 'proto.api.ListUnresolvedDashboardManagementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListUnresolvedDashboardManagementResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.ListUnresolvedDashboardManagementResponse.repeatedFields_, null);
};
goog.inherits(proto.api.ListUnresolvedDashboardManagementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListUnresolvedDashboardManagementResponse.displayName = 'proto.api.ListUnresolvedDashboardManagementResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListUnresolvedDashboardManagementResponse.Item = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ListUnresolvedDashboardManagementResponse.Item, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListUnresolvedDashboardManagementResponse.Item.displayName = 'proto.api.ListUnresolvedDashboardManagementResponse.Item';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CountIssuesByStatusManagementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CountIssuesByStatusManagementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CountIssuesByStatusManagementRequest.displayName = 'proto.api.CountIssuesByStatusManagementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CountIssuesByStatusManagementResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CountIssuesByStatusManagementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CountIssuesByStatusManagementResponse.displayName = 'proto.api.CountIssuesByStatusManagementResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CountIssuesByTypeManagementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CountIssuesByTypeManagementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CountIssuesByTypeManagementRequest.displayName = 'proto.api.CountIssuesByTypeManagementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CountIssuesByTypeManagementResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CountIssuesByTypeManagementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CountIssuesByTypeManagementResponse.displayName = 'proto.api.CountIssuesByTypeManagementResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.duration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.duration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.duration.displayName = 'proto.api.duration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CountIssuesWeeklyManagementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CountIssuesWeeklyManagementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CountIssuesWeeklyManagementRequest.displayName = 'proto.api.CountIssuesWeeklyManagementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.IssuesByType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.IssuesByType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.IssuesByType.displayName = 'proto.api.IssuesByType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CountIssuesWeeklyManagementResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.CountIssuesWeeklyManagementResponse.repeatedFields_, null);
};
goog.inherits(proto.api.CountIssuesWeeklyManagementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CountIssuesWeeklyManagementResponse.displayName = 'proto.api.CountIssuesWeeklyManagementResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetMyIssuesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetMyIssuesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetMyIssuesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetMyIssuesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetMyIssuesRequest}
 */
proto.api.GetMyIssuesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetMyIssuesRequest;
  return proto.api.GetMyIssuesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetMyIssuesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetMyIssuesRequest}
 */
proto.api.GetMyIssuesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetMyIssuesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetMyIssuesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetMyIssuesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetMyIssuesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.IssueStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.api.IssueStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.IssueStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.IssueStatus.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.IssueStatus}
 */
proto.api.IssueStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.IssueStatus;
  return proto.api.IssueStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.IssueStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.IssueStatus}
 */
proto.api.IssueStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.IssueStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.IssueStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.IssueStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.IssueStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.api.IssueStatus.Enum = {
  UNKNOWN: 0,
  COMPLETED: 1,
  PENDING: 2,
  CANCELLED: 3
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.IssueType.prototype.toObject = function(opt_includeInstance) {
  return proto.api.IssueType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.IssueType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.IssueType.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.IssueType}
 */
proto.api.IssueType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.IssueType;
  return proto.api.IssueType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.IssueType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.IssueType}
 */
proto.api.IssueType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.IssueType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.IssueType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.IssueType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.IssueType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.api.IssueType.Enum = {
  UNKNOWN: 0,
  ACCIDENT: 1,
  SERVICE: 2,
  VEHICLE_MISSING: 3,
  BREAKDOWN: 4,
  ROADSIDE_BREAKDOWN: 5,
  ACCOUNT_RELATED: 6
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.KioskIssueType.prototype.toObject = function(opt_includeInstance) {
  return proto.api.KioskIssueType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.KioskIssueType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.KioskIssueType.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.KioskIssueType}
 */
proto.api.KioskIssueType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.KioskIssueType;
  return proto.api.KioskIssueType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.KioskIssueType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.KioskIssueType}
 */
proto.api.KioskIssueType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.KioskIssueType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.KioskIssueType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.KioskIssueType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.KioskIssueType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.api.KioskIssueType.Enum = {
  UNKNOWN: 0,
  ELECTRIC_FAILURE: 1,
  FIRE_OR_SMOKE: 2,
  TEMPERATURE_RISE: 3,
  BATTERY_SWAP: 4,
  MECHANICAL_FAILURE: 5,
  NOISE: 6
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.IssueItem.prototype.toObject = function(opt_includeInstance) {
  return proto.api.IssueItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.IssueItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.IssueItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    serialId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    reportedBy: jspb.Message.getFieldWithDefault(msg, 3, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    addedAt: jspb.Message.getFieldWithDefault(msg, 5, 0),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    type: jspb.Message.getFieldWithDefault(msg, 7, 0),
    assignedTo: jspb.Message.getFieldWithDefault(msg, 8, ""),
    ownerOrgId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    dueDate: jspb.Message.getFieldWithDefault(msg, 10, 0),
    riderId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    issueSubType: jspb.Message.getFieldWithDefault(msg, 12, ""),
    issueReportType: jspb.Message.getFieldWithDefault(msg, 13, ""),
    issueOtherTypeText: jspb.Message.getFieldWithDefault(msg, 14, ""),
    kioskIssueType: jspb.Message.getFieldWithDefault(msg, 15, 0),
    issueHost: jspb.Message.getFieldWithDefault(msg, 16, 0),
    issueHostId: jspb.Message.getFieldWithDefault(msg, 17, ""),
    issueLevel: jspb.Message.getFieldWithDefault(msg, 18, 0),
    issueOccurDate: jspb.Message.getFieldWithDefault(msg, 19, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.IssueItem}
 */
proto.api.IssueItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.IssueItem;
  return proto.api.IssueItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.IssueItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.IssueItem}
 */
proto.api.IssueItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSerialId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportedBy(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAddedAt(value);
      break;
    case 6:
      var value = /** @type {!proto.api.IssueStatus.Enum} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {!proto.api.IssueType.Enum} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssignedTo(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerOrgId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDueDate(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setRiderId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setIssueSubType(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setIssueReportType(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setIssueOtherTypeText(value);
      break;
    case 15:
      var value = /** @type {!proto.api.KioskIssueType.Enum} */ (reader.readEnum());
      msg.setKioskIssueType(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIssueHost(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setIssueHostId(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIssueLevel(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIssueOccurDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.IssueItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.IssueItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.IssueItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.IssueItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSerialId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getReportedBy();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAddedAt();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getAssignedTo();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getOwnerOrgId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDueDate();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getRiderId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getIssueSubType();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getIssueReportType();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getIssueOtherTypeText();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getKioskIssueType();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getIssueHost();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getIssueHostId();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getIssueLevel();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getIssueOccurDate();
  if (f !== 0) {
    writer.writeInt64(
      19,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.IssueItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IssueItem} returns this
 */
proto.api.IssueItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 serial_id = 2;
 * @return {number}
 */
proto.api.IssueItem.prototype.getSerialId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.IssueItem} returns this
 */
proto.api.IssueItem.prototype.setSerialId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string reported_by = 3;
 * @return {string}
 */
proto.api.IssueItem.prototype.getReportedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IssueItem} returns this
 */
proto.api.IssueItem.prototype.setReportedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string vehicle_id = 4;
 * @return {string}
 */
proto.api.IssueItem.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IssueItem} returns this
 */
proto.api.IssueItem.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 added_at = 5;
 * @return {number}
 */
proto.api.IssueItem.prototype.getAddedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.IssueItem} returns this
 */
proto.api.IssueItem.prototype.setAddedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional IssueStatus.Enum status = 6;
 * @return {!proto.api.IssueStatus.Enum}
 */
proto.api.IssueItem.prototype.getStatus = function() {
  return /** @type {!proto.api.IssueStatus.Enum} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.api.IssueStatus.Enum} value
 * @return {!proto.api.IssueItem} returns this
 */
proto.api.IssueItem.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional IssueType.Enum type = 7;
 * @return {!proto.api.IssueType.Enum}
 */
proto.api.IssueItem.prototype.getType = function() {
  return /** @type {!proto.api.IssueType.Enum} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.api.IssueType.Enum} value
 * @return {!proto.api.IssueItem} returns this
 */
proto.api.IssueItem.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string assigned_to = 8;
 * @return {string}
 */
proto.api.IssueItem.prototype.getAssignedTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IssueItem} returns this
 */
proto.api.IssueItem.prototype.setAssignedTo = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string owner_org_id = 9;
 * @return {string}
 */
proto.api.IssueItem.prototype.getOwnerOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IssueItem} returns this
 */
proto.api.IssueItem.prototype.setOwnerOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int64 due_date = 10;
 * @return {number}
 */
proto.api.IssueItem.prototype.getDueDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.IssueItem} returns this
 */
proto.api.IssueItem.prototype.setDueDate = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string rider_id = 11;
 * @return {string}
 */
proto.api.IssueItem.prototype.getRiderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IssueItem} returns this
 */
proto.api.IssueItem.prototype.setRiderId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string issue_sub_type = 12;
 * @return {string}
 */
proto.api.IssueItem.prototype.getIssueSubType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IssueItem} returns this
 */
proto.api.IssueItem.prototype.setIssueSubType = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string issue_report_type = 13;
 * @return {string}
 */
proto.api.IssueItem.prototype.getIssueReportType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IssueItem} returns this
 */
proto.api.IssueItem.prototype.setIssueReportType = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string issue_other_type_text = 14;
 * @return {string}
 */
proto.api.IssueItem.prototype.getIssueOtherTypeText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IssueItem} returns this
 */
proto.api.IssueItem.prototype.setIssueOtherTypeText = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional KioskIssueType.Enum kiosk_issue_type = 15;
 * @return {!proto.api.KioskIssueType.Enum}
 */
proto.api.IssueItem.prototype.getKioskIssueType = function() {
  return /** @type {!proto.api.KioskIssueType.Enum} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.api.KioskIssueType.Enum} value
 * @return {!proto.api.IssueItem} returns this
 */
proto.api.IssueItem.prototype.setKioskIssueType = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional int32 issue_host = 16;
 * @return {number}
 */
proto.api.IssueItem.prototype.getIssueHost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.IssueItem} returns this
 */
proto.api.IssueItem.prototype.setIssueHost = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional string issue_host_id = 17;
 * @return {string}
 */
proto.api.IssueItem.prototype.getIssueHostId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IssueItem} returns this
 */
proto.api.IssueItem.prototype.setIssueHostId = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional int32 issue_level = 18;
 * @return {number}
 */
proto.api.IssueItem.prototype.getIssueLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.IssueItem} returns this
 */
proto.api.IssueItem.prototype.setIssueLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional int64 issue_occur_date = 19;
 * @return {number}
 */
proto.api.IssueItem.prototype.getIssueOccurDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.IssueItem} returns this
 */
proto.api.IssueItem.prototype.setIssueOccurDate = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.IssueItemListing.prototype.toObject = function(opt_includeInstance) {
  return proto.api.IssueItemListing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.IssueItemListing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.IssueItemListing.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    serialId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    reportedBy: jspb.Message.getFieldWithDefault(msg, 3, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    addedAt: jspb.Message.getFieldWithDefault(msg, 5, 0),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    type: jspb.Message.getFieldWithDefault(msg, 7, 0),
    assignedTo: jspb.Message.getFieldWithDefault(msg, 8, ""),
    ownerOrgId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    dueDate: jspb.Message.getFieldWithDefault(msg, 10, 0),
    riderId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    riderName: jspb.Message.getFieldWithDefault(msg, 12, ""),
    createdByName: jspb.Message.getFieldWithDefault(msg, 13, ""),
    assigneeName: jspb.Message.getFieldWithDefault(msg, 14, ""),
    vehiclePate: jspb.Message.getFieldWithDefault(msg, 15, ""),
    issueSubType: jspb.Message.getFieldWithDefault(msg, 16, ""),
    issueReportType: jspb.Message.getFieldWithDefault(msg, 17, ""),
    issueOtherTypeText: jspb.Message.getFieldWithDefault(msg, 18, ""),
    kioskIssueType: jspb.Message.getFieldWithDefault(msg, 19, 0),
    issueHost: jspb.Message.getFieldWithDefault(msg, 20, 0),
    issueHostId: jspb.Message.getFieldWithDefault(msg, 21, ""),
    issueLevel: jspb.Message.getFieldWithDefault(msg, 22, 0),
    issueOccurDate: jspb.Message.getFieldWithDefault(msg, 23, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.IssueItemListing}
 */
proto.api.IssueItemListing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.IssueItemListing;
  return proto.api.IssueItemListing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.IssueItemListing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.IssueItemListing}
 */
proto.api.IssueItemListing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSerialId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportedBy(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAddedAt(value);
      break;
    case 6:
      var value = /** @type {!proto.api.IssueStatus.Enum} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {!proto.api.IssueType.Enum} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssignedTo(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerOrgId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDueDate(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setRiderId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setRiderName(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedByName(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssigneeName(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehiclePate(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setIssueSubType(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setIssueReportType(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setIssueOtherTypeText(value);
      break;
    case 19:
      var value = /** @type {!proto.api.KioskIssueType.Enum} */ (reader.readEnum());
      msg.setKioskIssueType(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIssueHost(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setIssueHostId(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIssueLevel(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIssueOccurDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.IssueItemListing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.IssueItemListing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.IssueItemListing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.IssueItemListing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSerialId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getReportedBy();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAddedAt();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getAssignedTo();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getOwnerOrgId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDueDate();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getRiderId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getRiderName();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCreatedByName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getAssigneeName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getVehiclePate();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getIssueSubType();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getIssueReportType();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getIssueOtherTypeText();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getKioskIssueType();
  if (f !== 0.0) {
    writer.writeEnum(
      19,
      f
    );
  }
  f = message.getIssueHost();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getIssueHostId();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getIssueLevel();
  if (f !== 0) {
    writer.writeInt32(
      22,
      f
    );
  }
  f = message.getIssueOccurDate();
  if (f !== 0) {
    writer.writeInt64(
      23,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.IssueItemListing.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IssueItemListing} returns this
 */
proto.api.IssueItemListing.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 serial_id = 2;
 * @return {number}
 */
proto.api.IssueItemListing.prototype.getSerialId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.IssueItemListing} returns this
 */
proto.api.IssueItemListing.prototype.setSerialId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string reported_by = 3;
 * @return {string}
 */
proto.api.IssueItemListing.prototype.getReportedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IssueItemListing} returns this
 */
proto.api.IssueItemListing.prototype.setReportedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string vehicle_id = 4;
 * @return {string}
 */
proto.api.IssueItemListing.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IssueItemListing} returns this
 */
proto.api.IssueItemListing.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 added_at = 5;
 * @return {number}
 */
proto.api.IssueItemListing.prototype.getAddedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.IssueItemListing} returns this
 */
proto.api.IssueItemListing.prototype.setAddedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional IssueStatus.Enum status = 6;
 * @return {!proto.api.IssueStatus.Enum}
 */
proto.api.IssueItemListing.prototype.getStatus = function() {
  return /** @type {!proto.api.IssueStatus.Enum} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.api.IssueStatus.Enum} value
 * @return {!proto.api.IssueItemListing} returns this
 */
proto.api.IssueItemListing.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional IssueType.Enum type = 7;
 * @return {!proto.api.IssueType.Enum}
 */
proto.api.IssueItemListing.prototype.getType = function() {
  return /** @type {!proto.api.IssueType.Enum} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.api.IssueType.Enum} value
 * @return {!proto.api.IssueItemListing} returns this
 */
proto.api.IssueItemListing.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string assigned_to = 8;
 * @return {string}
 */
proto.api.IssueItemListing.prototype.getAssignedTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IssueItemListing} returns this
 */
proto.api.IssueItemListing.prototype.setAssignedTo = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string owner_org_id = 9;
 * @return {string}
 */
proto.api.IssueItemListing.prototype.getOwnerOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IssueItemListing} returns this
 */
proto.api.IssueItemListing.prototype.setOwnerOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int64 due_date = 10;
 * @return {number}
 */
proto.api.IssueItemListing.prototype.getDueDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.IssueItemListing} returns this
 */
proto.api.IssueItemListing.prototype.setDueDate = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string rider_id = 11;
 * @return {string}
 */
proto.api.IssueItemListing.prototype.getRiderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IssueItemListing} returns this
 */
proto.api.IssueItemListing.prototype.setRiderId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string rider_name = 12;
 * @return {string}
 */
proto.api.IssueItemListing.prototype.getRiderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IssueItemListing} returns this
 */
proto.api.IssueItemListing.prototype.setRiderName = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string created_by_name = 13;
 * @return {string}
 */
proto.api.IssueItemListing.prototype.getCreatedByName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IssueItemListing} returns this
 */
proto.api.IssueItemListing.prototype.setCreatedByName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string assignee_name = 14;
 * @return {string}
 */
proto.api.IssueItemListing.prototype.getAssigneeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IssueItemListing} returns this
 */
proto.api.IssueItemListing.prototype.setAssigneeName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string vehicle_pate = 15;
 * @return {string}
 */
proto.api.IssueItemListing.prototype.getVehiclePate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IssueItemListing} returns this
 */
proto.api.IssueItemListing.prototype.setVehiclePate = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string issue_sub_type = 16;
 * @return {string}
 */
proto.api.IssueItemListing.prototype.getIssueSubType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IssueItemListing} returns this
 */
proto.api.IssueItemListing.prototype.setIssueSubType = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string issue_report_type = 17;
 * @return {string}
 */
proto.api.IssueItemListing.prototype.getIssueReportType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IssueItemListing} returns this
 */
proto.api.IssueItemListing.prototype.setIssueReportType = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string issue_other_type_text = 18;
 * @return {string}
 */
proto.api.IssueItemListing.prototype.getIssueOtherTypeText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IssueItemListing} returns this
 */
proto.api.IssueItemListing.prototype.setIssueOtherTypeText = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional KioskIssueType.Enum kiosk_issue_type = 19;
 * @return {!proto.api.KioskIssueType.Enum}
 */
proto.api.IssueItemListing.prototype.getKioskIssueType = function() {
  return /** @type {!proto.api.KioskIssueType.Enum} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {!proto.api.KioskIssueType.Enum} value
 * @return {!proto.api.IssueItemListing} returns this
 */
proto.api.IssueItemListing.prototype.setKioskIssueType = function(value) {
  return jspb.Message.setProto3EnumField(this, 19, value);
};


/**
 * optional int32 issue_host = 20;
 * @return {number}
 */
proto.api.IssueItemListing.prototype.getIssueHost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.IssueItemListing} returns this
 */
proto.api.IssueItemListing.prototype.setIssueHost = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional string issue_host_id = 21;
 * @return {string}
 */
proto.api.IssueItemListing.prototype.getIssueHostId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IssueItemListing} returns this
 */
proto.api.IssueItemListing.prototype.setIssueHostId = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional int32 issue_level = 22;
 * @return {number}
 */
proto.api.IssueItemListing.prototype.getIssueLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.IssueItemListing} returns this
 */
proto.api.IssueItemListing.prototype.setIssueLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional int64 issue_occur_date = 23;
 * @return {number}
 */
proto.api.IssueItemListing.prototype.getIssueOccurDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.IssueItemListing} returns this
 */
proto.api.IssueItemListing.prototype.setIssueOccurDate = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.IssueUpdateItem.prototype.toObject = function(opt_includeInstance) {
  return proto.api.IssueUpdateItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.IssueUpdateItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.IssueUpdateItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    assignedTo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    dueDate: jspb.Message.getFieldWithDefault(msg, 5, 0),
    riderId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    issueSubType: jspb.Message.getFieldWithDefault(msg, 12, ""),
    issueReportType: jspb.Message.getFieldWithDefault(msg, 13, ""),
    issueOtherTypeText: jspb.Message.getFieldWithDefault(msg, 14, ""),
    status: jspb.Message.getFieldWithDefault(msg, 15, 0),
    issueHost: jspb.Message.getFieldWithDefault(msg, 16, 0),
    issueHostId: jspb.Message.getFieldWithDefault(msg, 17, ""),
    kioskIssueType: jspb.Message.getFieldWithDefault(msg, 18, 0),
    issueLevel: jspb.Message.getFieldWithDefault(msg, 19, 0),
    issueOccurDate: jspb.Message.getFieldWithDefault(msg, 20, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.IssueUpdateItem}
 */
proto.api.IssueUpdateItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.IssueUpdateItem;
  return proto.api.IssueUpdateItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.IssueUpdateItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.IssueUpdateItem}
 */
proto.api.IssueUpdateItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 3:
      var value = /** @type {!proto.api.IssueType.Enum} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssignedTo(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDueDate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRiderId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setIssueSubType(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setIssueReportType(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setIssueOtherTypeText(value);
      break;
    case 15:
      var value = /** @type {!proto.api.IssueStatus.Enum} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIssueHost(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setIssueHostId(value);
      break;
    case 18:
      var value = /** @type {!proto.api.KioskIssueType.Enum} */ (reader.readEnum());
      msg.setKioskIssueType(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIssueLevel(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIssueOccurDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.IssueUpdateItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.IssueUpdateItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.IssueUpdateItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.IssueUpdateItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAssignedTo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDueDate();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getRiderId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIssueSubType();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getIssueReportType();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getIssueOtherTypeText();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getIssueHost();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getIssueHostId();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getKioskIssueType();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
  f = message.getIssueLevel();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getIssueOccurDate();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.api.IssueUpdateItem.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IssueUpdateItem} returns this
 */
proto.api.IssueUpdateItem.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional IssueType.Enum type = 3;
 * @return {!proto.api.IssueType.Enum}
 */
proto.api.IssueUpdateItem.prototype.getType = function() {
  return /** @type {!proto.api.IssueType.Enum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.api.IssueType.Enum} value
 * @return {!proto.api.IssueUpdateItem} returns this
 */
proto.api.IssueUpdateItem.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string assigned_to = 4;
 * @return {string}
 */
proto.api.IssueUpdateItem.prototype.getAssignedTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IssueUpdateItem} returns this
 */
proto.api.IssueUpdateItem.prototype.setAssignedTo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 due_date = 5;
 * @return {number}
 */
proto.api.IssueUpdateItem.prototype.getDueDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.IssueUpdateItem} returns this
 */
proto.api.IssueUpdateItem.prototype.setDueDate = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string rider_id = 6;
 * @return {string}
 */
proto.api.IssueUpdateItem.prototype.getRiderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IssueUpdateItem} returns this
 */
proto.api.IssueUpdateItem.prototype.setRiderId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string issue_sub_type = 12;
 * @return {string}
 */
proto.api.IssueUpdateItem.prototype.getIssueSubType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IssueUpdateItem} returns this
 */
proto.api.IssueUpdateItem.prototype.setIssueSubType = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string issue_report_type = 13;
 * @return {string}
 */
proto.api.IssueUpdateItem.prototype.getIssueReportType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IssueUpdateItem} returns this
 */
proto.api.IssueUpdateItem.prototype.setIssueReportType = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string issue_other_type_text = 14;
 * @return {string}
 */
proto.api.IssueUpdateItem.prototype.getIssueOtherTypeText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IssueUpdateItem} returns this
 */
proto.api.IssueUpdateItem.prototype.setIssueOtherTypeText = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional IssueStatus.Enum status = 15;
 * @return {!proto.api.IssueStatus.Enum}
 */
proto.api.IssueUpdateItem.prototype.getStatus = function() {
  return /** @type {!proto.api.IssueStatus.Enum} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.api.IssueStatus.Enum} value
 * @return {!proto.api.IssueUpdateItem} returns this
 */
proto.api.IssueUpdateItem.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional int32 issue_host = 16;
 * @return {number}
 */
proto.api.IssueUpdateItem.prototype.getIssueHost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.IssueUpdateItem} returns this
 */
proto.api.IssueUpdateItem.prototype.setIssueHost = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional string issue_host_id = 17;
 * @return {string}
 */
proto.api.IssueUpdateItem.prototype.getIssueHostId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IssueUpdateItem} returns this
 */
proto.api.IssueUpdateItem.prototype.setIssueHostId = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional KioskIssueType.Enum kiosk_issue_type = 18;
 * @return {!proto.api.KioskIssueType.Enum}
 */
proto.api.IssueUpdateItem.prototype.getKioskIssueType = function() {
  return /** @type {!proto.api.KioskIssueType.Enum} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {!proto.api.KioskIssueType.Enum} value
 * @return {!proto.api.IssueUpdateItem} returns this
 */
proto.api.IssueUpdateItem.prototype.setKioskIssueType = function(value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};


/**
 * optional int32 issue_level = 19;
 * @return {number}
 */
proto.api.IssueUpdateItem.prototype.getIssueLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.IssueUpdateItem} returns this
 */
proto.api.IssueUpdateItem.prototype.setIssueLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int64 issue_occur_date = 20;
 * @return {number}
 */
proto.api.IssueUpdateItem.prototype.getIssueOccurDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.IssueUpdateItem} returns this
 */
proto.api.IssueUpdateItem.prototype.setIssueOccurDate = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListIssuesManagementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListIssuesManagementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListIssuesManagementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListIssuesManagementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerOrgId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    page: (f = msg.getPage()) && page_pb.PageRequest.toObject(includeInstance, f),
    filter: (f = msg.getFilter()) && proto.api.ListIssuesManagementRequest.Filter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListIssuesManagementRequest}
 */
proto.api.ListIssuesManagementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListIssuesManagementRequest;
  return proto.api.ListIssuesManagementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListIssuesManagementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListIssuesManagementRequest}
 */
proto.api.ListIssuesManagementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerOrgId(value);
      break;
    case 2:
      var value = new page_pb.PageRequest;
      reader.readMessage(value,page_pb.PageRequest.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    case 3:
      var value = new proto.api.ListIssuesManagementRequest.Filter;
      reader.readMessage(value,proto.api.ListIssuesManagementRequest.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListIssuesManagementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListIssuesManagementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListIssuesManagementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListIssuesManagementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerOrgId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      page_pb.PageRequest.serializeBinaryToWriter
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.ListIssuesManagementRequest.Filter.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListIssuesManagementRequest.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListIssuesManagementRequest.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListIssuesManagementRequest.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListIssuesManagementRequest.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: (f = msg.getStatus()) && filter_pb.Filters.In.toObject(includeInstance, f),
    addedAt: (f = msg.getAddedAt()) && filter_pb.Filters.Range.toObject(includeInstance, f),
    type: (f = msg.getType()) && filter_pb.Filters.In.toObject(includeInstance, f),
    assignee: (f = msg.getAssignee()) && filter_pb.Filters.In.toObject(includeInstance, f),
    isArchived: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListIssuesManagementRequest.Filter}
 */
proto.api.ListIssuesManagementRequest.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListIssuesManagementRequest.Filter;
  return proto.api.ListIssuesManagementRequest.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListIssuesManagementRequest.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListIssuesManagementRequest.Filter}
 */
proto.api.ListIssuesManagementRequest.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new filter_pb.Filters.In;
      reader.readMessage(value,filter_pb.Filters.In.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 2:
      var value = new filter_pb.Filters.Range;
      reader.readMessage(value,filter_pb.Filters.Range.deserializeBinaryFromReader);
      msg.setAddedAt(value);
      break;
    case 3:
      var value = new filter_pb.Filters.In;
      reader.readMessage(value,filter_pb.Filters.In.deserializeBinaryFromReader);
      msg.setType(value);
      break;
    case 4:
      var value = new filter_pb.Filters.In;
      reader.readMessage(value,filter_pb.Filters.In.deserializeBinaryFromReader);
      msg.setAssignee(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsArchived(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListIssuesManagementRequest.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListIssuesManagementRequest.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListIssuesManagementRequest.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListIssuesManagementRequest.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      filter_pb.Filters.In.serializeBinaryToWriter
    );
  }
  f = message.getAddedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      filter_pb.Filters.Range.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      filter_pb.Filters.In.serializeBinaryToWriter
    );
  }
  f = message.getAssignee();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      filter_pb.Filters.In.serializeBinaryToWriter
    );
  }
  f = message.getIsArchived();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional Filters.In status = 1;
 * @return {?proto.api.Filters.In}
 */
proto.api.ListIssuesManagementRequest.Filter.prototype.getStatus = function() {
  return /** @type{?proto.api.Filters.In} */ (
    jspb.Message.getWrapperField(this, filter_pb.Filters.In, 1));
};


/**
 * @param {?proto.api.Filters.In|undefined} value
 * @return {!proto.api.ListIssuesManagementRequest.Filter} returns this
*/
proto.api.ListIssuesManagementRequest.Filter.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListIssuesManagementRequest.Filter} returns this
 */
proto.api.ListIssuesManagementRequest.Filter.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListIssuesManagementRequest.Filter.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Filters.Range added_at = 2;
 * @return {?proto.api.Filters.Range}
 */
proto.api.ListIssuesManagementRequest.Filter.prototype.getAddedAt = function() {
  return /** @type{?proto.api.Filters.Range} */ (
    jspb.Message.getWrapperField(this, filter_pb.Filters.Range, 2));
};


/**
 * @param {?proto.api.Filters.Range|undefined} value
 * @return {!proto.api.ListIssuesManagementRequest.Filter} returns this
*/
proto.api.ListIssuesManagementRequest.Filter.prototype.setAddedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListIssuesManagementRequest.Filter} returns this
 */
proto.api.ListIssuesManagementRequest.Filter.prototype.clearAddedAt = function() {
  return this.setAddedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListIssuesManagementRequest.Filter.prototype.hasAddedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Filters.In type = 3;
 * @return {?proto.api.Filters.In}
 */
proto.api.ListIssuesManagementRequest.Filter.prototype.getType = function() {
  return /** @type{?proto.api.Filters.In} */ (
    jspb.Message.getWrapperField(this, filter_pb.Filters.In, 3));
};


/**
 * @param {?proto.api.Filters.In|undefined} value
 * @return {!proto.api.ListIssuesManagementRequest.Filter} returns this
*/
proto.api.ListIssuesManagementRequest.Filter.prototype.setType = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListIssuesManagementRequest.Filter} returns this
 */
proto.api.ListIssuesManagementRequest.Filter.prototype.clearType = function() {
  return this.setType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListIssuesManagementRequest.Filter.prototype.hasType = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Filters.In assignee = 4;
 * @return {?proto.api.Filters.In}
 */
proto.api.ListIssuesManagementRequest.Filter.prototype.getAssignee = function() {
  return /** @type{?proto.api.Filters.In} */ (
    jspb.Message.getWrapperField(this, filter_pb.Filters.In, 4));
};


/**
 * @param {?proto.api.Filters.In|undefined} value
 * @return {!proto.api.ListIssuesManagementRequest.Filter} returns this
*/
proto.api.ListIssuesManagementRequest.Filter.prototype.setAssignee = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListIssuesManagementRequest.Filter} returns this
 */
proto.api.ListIssuesManagementRequest.Filter.prototype.clearAssignee = function() {
  return this.setAssignee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListIssuesManagementRequest.Filter.prototype.hasAssignee = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool is_archived = 5;
 * @return {boolean}
 */
proto.api.ListIssuesManagementRequest.Filter.prototype.getIsArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.ListIssuesManagementRequest.Filter} returns this
 */
proto.api.ListIssuesManagementRequest.Filter.prototype.setIsArchived = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string owner_org_id = 1;
 * @return {string}
 */
proto.api.ListIssuesManagementRequest.prototype.getOwnerOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ListIssuesManagementRequest} returns this
 */
proto.api.ListIssuesManagementRequest.prototype.setOwnerOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PageRequest page = 2;
 * @return {?proto.api.PageRequest}
 */
proto.api.ListIssuesManagementRequest.prototype.getPage = function() {
  return /** @type{?proto.api.PageRequest} */ (
    jspb.Message.getWrapperField(this, page_pb.PageRequest, 2));
};


/**
 * @param {?proto.api.PageRequest|undefined} value
 * @return {!proto.api.ListIssuesManagementRequest} returns this
*/
proto.api.ListIssuesManagementRequest.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListIssuesManagementRequest} returns this
 */
proto.api.ListIssuesManagementRequest.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListIssuesManagementRequest.prototype.hasPage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Filter filter = 3;
 * @return {?proto.api.ListIssuesManagementRequest.Filter}
 */
proto.api.ListIssuesManagementRequest.prototype.getFilter = function() {
  return /** @type{?proto.api.ListIssuesManagementRequest.Filter} */ (
    jspb.Message.getWrapperField(this, proto.api.ListIssuesManagementRequest.Filter, 3));
};


/**
 * @param {?proto.api.ListIssuesManagementRequest.Filter|undefined} value
 * @return {!proto.api.ListIssuesManagementRequest} returns this
*/
proto.api.ListIssuesManagementRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListIssuesManagementRequest} returns this
 */
proto.api.ListIssuesManagementRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListIssuesManagementRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListIssuesAdminRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListIssuesAdminRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListIssuesAdminRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListIssuesAdminRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerOrgId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    page: (f = msg.getPage()) && page_pb.PageRequest.toObject(includeInstance, f),
    filter: (f = msg.getFilter()) && proto.api.ListIssuesAdminRequest.Filter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListIssuesAdminRequest}
 */
proto.api.ListIssuesAdminRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListIssuesAdminRequest;
  return proto.api.ListIssuesAdminRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListIssuesAdminRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListIssuesAdminRequest}
 */
proto.api.ListIssuesAdminRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerOrgId(value);
      break;
    case 2:
      var value = new page_pb.PageRequest;
      reader.readMessage(value,page_pb.PageRequest.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    case 3:
      var value = new proto.api.ListIssuesAdminRequest.Filter;
      reader.readMessage(value,proto.api.ListIssuesAdminRequest.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListIssuesAdminRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListIssuesAdminRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListIssuesAdminRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListIssuesAdminRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerOrgId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      page_pb.PageRequest.serializeBinaryToWriter
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.ListIssuesAdminRequest.Filter.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListIssuesAdminRequest.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListIssuesAdminRequest.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListIssuesAdminRequest.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListIssuesAdminRequest.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: (f = msg.getStatus()) && filter_pb.Filters.In.toObject(includeInstance, f),
    addedAt: (f = msg.getAddedAt()) && filter_pb.Filters.Range.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListIssuesAdminRequest.Filter}
 */
proto.api.ListIssuesAdminRequest.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListIssuesAdminRequest.Filter;
  return proto.api.ListIssuesAdminRequest.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListIssuesAdminRequest.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListIssuesAdminRequest.Filter}
 */
proto.api.ListIssuesAdminRequest.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new filter_pb.Filters.In;
      reader.readMessage(value,filter_pb.Filters.In.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 2:
      var value = new filter_pb.Filters.Range;
      reader.readMessage(value,filter_pb.Filters.Range.deserializeBinaryFromReader);
      msg.setAddedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListIssuesAdminRequest.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListIssuesAdminRequest.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListIssuesAdminRequest.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListIssuesAdminRequest.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      filter_pb.Filters.In.serializeBinaryToWriter
    );
  }
  f = message.getAddedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      filter_pb.Filters.Range.serializeBinaryToWriter
    );
  }
};


/**
 * optional Filters.In status = 1;
 * @return {?proto.api.Filters.In}
 */
proto.api.ListIssuesAdminRequest.Filter.prototype.getStatus = function() {
  return /** @type{?proto.api.Filters.In} */ (
    jspb.Message.getWrapperField(this, filter_pb.Filters.In, 1));
};


/**
 * @param {?proto.api.Filters.In|undefined} value
 * @return {!proto.api.ListIssuesAdminRequest.Filter} returns this
*/
proto.api.ListIssuesAdminRequest.Filter.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListIssuesAdminRequest.Filter} returns this
 */
proto.api.ListIssuesAdminRequest.Filter.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListIssuesAdminRequest.Filter.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Filters.Range added_at = 2;
 * @return {?proto.api.Filters.Range}
 */
proto.api.ListIssuesAdminRequest.Filter.prototype.getAddedAt = function() {
  return /** @type{?proto.api.Filters.Range} */ (
    jspb.Message.getWrapperField(this, filter_pb.Filters.Range, 2));
};


/**
 * @param {?proto.api.Filters.Range|undefined} value
 * @return {!proto.api.ListIssuesAdminRequest.Filter} returns this
*/
proto.api.ListIssuesAdminRequest.Filter.prototype.setAddedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListIssuesAdminRequest.Filter} returns this
 */
proto.api.ListIssuesAdminRequest.Filter.prototype.clearAddedAt = function() {
  return this.setAddedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListIssuesAdminRequest.Filter.prototype.hasAddedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string owner_org_id = 1;
 * @return {string}
 */
proto.api.ListIssuesAdminRequest.prototype.getOwnerOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ListIssuesAdminRequest} returns this
 */
proto.api.ListIssuesAdminRequest.prototype.setOwnerOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PageRequest page = 2;
 * @return {?proto.api.PageRequest}
 */
proto.api.ListIssuesAdminRequest.prototype.getPage = function() {
  return /** @type{?proto.api.PageRequest} */ (
    jspb.Message.getWrapperField(this, page_pb.PageRequest, 2));
};


/**
 * @param {?proto.api.PageRequest|undefined} value
 * @return {!proto.api.ListIssuesAdminRequest} returns this
*/
proto.api.ListIssuesAdminRequest.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListIssuesAdminRequest} returns this
 */
proto.api.ListIssuesAdminRequest.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListIssuesAdminRequest.prototype.hasPage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Filter filter = 3;
 * @return {?proto.api.ListIssuesAdminRequest.Filter}
 */
proto.api.ListIssuesAdminRequest.prototype.getFilter = function() {
  return /** @type{?proto.api.ListIssuesAdminRequest.Filter} */ (
    jspb.Message.getWrapperField(this, proto.api.ListIssuesAdminRequest.Filter, 3));
};


/**
 * @param {?proto.api.ListIssuesAdminRequest.Filter|undefined} value
 * @return {!proto.api.ListIssuesAdminRequest} returns this
*/
proto.api.ListIssuesAdminRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListIssuesAdminRequest} returns this
 */
proto.api.ListIssuesAdminRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListIssuesAdminRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.ListIssuesManagementResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListIssuesManagementResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListIssuesManagementResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListIssuesManagementResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListIssuesManagementResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: (f = msg.getPage()) && page_pb.PageResponse.toObject(includeInstance, f),
    issuesList: jspb.Message.toObjectList(msg.getIssuesList(),
    proto.api.ListIssuesManagementResponse.Item.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListIssuesManagementResponse}
 */
proto.api.ListIssuesManagementResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListIssuesManagementResponse;
  return proto.api.ListIssuesManagementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListIssuesManagementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListIssuesManagementResponse}
 */
proto.api.ListIssuesManagementResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new page_pb.PageResponse;
      reader.readMessage(value,page_pb.PageResponse.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    case 2:
      var value = new proto.api.ListIssuesManagementResponse.Item;
      reader.readMessage(value,proto.api.ListIssuesManagementResponse.Item.deserializeBinaryFromReader);
      msg.addIssues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListIssuesManagementResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListIssuesManagementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListIssuesManagementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListIssuesManagementResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      page_pb.PageResponse.serializeBinaryToWriter
    );
  }
  f = message.getIssuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.api.ListIssuesManagementResponse.Item.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListIssuesManagementResponse.Item.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListIssuesManagementResponse.Item.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListIssuesManagementResponse.Item} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListIssuesManagementResponse.Item.toObject = function(includeInstance, msg) {
  var f, obj = {
    basic: (f = msg.getBasic()) && proto.api.IssueItemListing.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListIssuesManagementResponse.Item}
 */
proto.api.ListIssuesManagementResponse.Item.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListIssuesManagementResponse.Item;
  return proto.api.ListIssuesManagementResponse.Item.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListIssuesManagementResponse.Item} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListIssuesManagementResponse.Item}
 */
proto.api.ListIssuesManagementResponse.Item.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.IssueItemListing;
      reader.readMessage(value,proto.api.IssueItemListing.deserializeBinaryFromReader);
      msg.setBasic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListIssuesManagementResponse.Item.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListIssuesManagementResponse.Item.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListIssuesManagementResponse.Item} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListIssuesManagementResponse.Item.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBasic();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.IssueItemListing.serializeBinaryToWriter
    );
  }
};


/**
 * optional IssueItemListing basic = 1;
 * @return {?proto.api.IssueItemListing}
 */
proto.api.ListIssuesManagementResponse.Item.prototype.getBasic = function() {
  return /** @type{?proto.api.IssueItemListing} */ (
    jspb.Message.getWrapperField(this, proto.api.IssueItemListing, 1));
};


/**
 * @param {?proto.api.IssueItemListing|undefined} value
 * @return {!proto.api.ListIssuesManagementResponse.Item} returns this
*/
proto.api.ListIssuesManagementResponse.Item.prototype.setBasic = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListIssuesManagementResponse.Item} returns this
 */
proto.api.ListIssuesManagementResponse.Item.prototype.clearBasic = function() {
  return this.setBasic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListIssuesManagementResponse.Item.prototype.hasBasic = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PageResponse page = 1;
 * @return {?proto.api.PageResponse}
 */
proto.api.ListIssuesManagementResponse.prototype.getPage = function() {
  return /** @type{?proto.api.PageResponse} */ (
    jspb.Message.getWrapperField(this, page_pb.PageResponse, 1));
};


/**
 * @param {?proto.api.PageResponse|undefined} value
 * @return {!proto.api.ListIssuesManagementResponse} returns this
*/
proto.api.ListIssuesManagementResponse.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListIssuesManagementResponse} returns this
 */
proto.api.ListIssuesManagementResponse.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListIssuesManagementResponse.prototype.hasPage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Item issues = 2;
 * @return {!Array<!proto.api.ListIssuesManagementResponse.Item>}
 */
proto.api.ListIssuesManagementResponse.prototype.getIssuesList = function() {
  return /** @type{!Array<!proto.api.ListIssuesManagementResponse.Item>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.ListIssuesManagementResponse.Item, 2));
};


/**
 * @param {!Array<!proto.api.ListIssuesManagementResponse.Item>} value
 * @return {!proto.api.ListIssuesManagementResponse} returns this
*/
proto.api.ListIssuesManagementResponse.prototype.setIssuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.api.ListIssuesManagementResponse.Item=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.ListIssuesManagementResponse.Item}
 */
proto.api.ListIssuesManagementResponse.prototype.addIssues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.api.ListIssuesManagementResponse.Item, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.ListIssuesManagementResponse} returns this
 */
proto.api.ListIssuesManagementResponse.prototype.clearIssuesList = function() {
  return this.setIssuesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.ListIssuesAdminResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListIssuesAdminResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListIssuesAdminResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListIssuesAdminResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListIssuesAdminResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: (f = msg.getPage()) && page_pb.PageResponse.toObject(includeInstance, f),
    issuesList: jspb.Message.toObjectList(msg.getIssuesList(),
    proto.api.ListIssuesAdminResponse.Item.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListIssuesAdminResponse}
 */
proto.api.ListIssuesAdminResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListIssuesAdminResponse;
  return proto.api.ListIssuesAdminResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListIssuesAdminResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListIssuesAdminResponse}
 */
proto.api.ListIssuesAdminResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new page_pb.PageResponse;
      reader.readMessage(value,page_pb.PageResponse.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    case 2:
      var value = new proto.api.ListIssuesAdminResponse.Item;
      reader.readMessage(value,proto.api.ListIssuesAdminResponse.Item.deserializeBinaryFromReader);
      msg.addIssues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListIssuesAdminResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListIssuesAdminResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListIssuesAdminResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListIssuesAdminResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      page_pb.PageResponse.serializeBinaryToWriter
    );
  }
  f = message.getIssuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.api.ListIssuesAdminResponse.Item.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListIssuesAdminResponse.Item.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListIssuesAdminResponse.Item.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListIssuesAdminResponse.Item} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListIssuesAdminResponse.Item.toObject = function(includeInstance, msg) {
  var f, obj = {
    basic: (f = msg.getBasic()) && proto.api.IssueItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListIssuesAdminResponse.Item}
 */
proto.api.ListIssuesAdminResponse.Item.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListIssuesAdminResponse.Item;
  return proto.api.ListIssuesAdminResponse.Item.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListIssuesAdminResponse.Item} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListIssuesAdminResponse.Item}
 */
proto.api.ListIssuesAdminResponse.Item.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.IssueItem;
      reader.readMessage(value,proto.api.IssueItem.deserializeBinaryFromReader);
      msg.setBasic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListIssuesAdminResponse.Item.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListIssuesAdminResponse.Item.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListIssuesAdminResponse.Item} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListIssuesAdminResponse.Item.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBasic();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.IssueItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional IssueItem basic = 1;
 * @return {?proto.api.IssueItem}
 */
proto.api.ListIssuesAdminResponse.Item.prototype.getBasic = function() {
  return /** @type{?proto.api.IssueItem} */ (
    jspb.Message.getWrapperField(this, proto.api.IssueItem, 1));
};


/**
 * @param {?proto.api.IssueItem|undefined} value
 * @return {!proto.api.ListIssuesAdminResponse.Item} returns this
*/
proto.api.ListIssuesAdminResponse.Item.prototype.setBasic = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListIssuesAdminResponse.Item} returns this
 */
proto.api.ListIssuesAdminResponse.Item.prototype.clearBasic = function() {
  return this.setBasic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListIssuesAdminResponse.Item.prototype.hasBasic = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PageResponse page = 1;
 * @return {?proto.api.PageResponse}
 */
proto.api.ListIssuesAdminResponse.prototype.getPage = function() {
  return /** @type{?proto.api.PageResponse} */ (
    jspb.Message.getWrapperField(this, page_pb.PageResponse, 1));
};


/**
 * @param {?proto.api.PageResponse|undefined} value
 * @return {!proto.api.ListIssuesAdminResponse} returns this
*/
proto.api.ListIssuesAdminResponse.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListIssuesAdminResponse} returns this
 */
proto.api.ListIssuesAdminResponse.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListIssuesAdminResponse.prototype.hasPage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Item issues = 2;
 * @return {!Array<!proto.api.ListIssuesAdminResponse.Item>}
 */
proto.api.ListIssuesAdminResponse.prototype.getIssuesList = function() {
  return /** @type{!Array<!proto.api.ListIssuesAdminResponse.Item>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.ListIssuesAdminResponse.Item, 2));
};


/**
 * @param {!Array<!proto.api.ListIssuesAdminResponse.Item>} value
 * @return {!proto.api.ListIssuesAdminResponse} returns this
*/
proto.api.ListIssuesAdminResponse.prototype.setIssuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.api.ListIssuesAdminResponse.Item=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.ListIssuesAdminResponse.Item}
 */
proto.api.ListIssuesAdminResponse.prototype.addIssues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.api.ListIssuesAdminResponse.Item, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.ListIssuesAdminResponse} returns this
 */
proto.api.ListIssuesAdminResponse.prototype.clearIssuesList = function() {
  return this.setIssuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateIssueRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateIssueRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateIssueRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateIssueRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    issue: (f = msg.getIssue()) && proto.api.IssueUpdateItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateIssueRequest}
 */
proto.api.UpdateIssueRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateIssueRequest;
  return proto.api.UpdateIssueRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateIssueRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateIssueRequest}
 */
proto.api.UpdateIssueRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.api.IssueUpdateItem;
      reader.readMessage(value,proto.api.IssueUpdateItem.deserializeBinaryFromReader);
      msg.setIssue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateIssueRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateIssueRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateIssueRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateIssueRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIssue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.IssueUpdateItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.UpdateIssueRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateIssueRequest} returns this
 */
proto.api.UpdateIssueRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional IssueUpdateItem issue = 2;
 * @return {?proto.api.IssueUpdateItem}
 */
proto.api.UpdateIssueRequest.prototype.getIssue = function() {
  return /** @type{?proto.api.IssueUpdateItem} */ (
    jspb.Message.getWrapperField(this, proto.api.IssueUpdateItem, 2));
};


/**
 * @param {?proto.api.IssueUpdateItem|undefined} value
 * @return {!proto.api.UpdateIssueRequest} returns this
*/
proto.api.UpdateIssueRequest.prototype.setIssue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.UpdateIssueRequest} returns this
 */
proto.api.UpdateIssueRequest.prototype.clearIssue = function() {
  return this.setIssue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UpdateIssueRequest.prototype.hasIssue = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateIssueResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateIssueResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateIssueResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateIssueResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    issue: (f = msg.getIssue()) && proto.api.IssueItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateIssueResponse}
 */
proto.api.UpdateIssueResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateIssueResponse;
  return proto.api.UpdateIssueResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateIssueResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateIssueResponse}
 */
proto.api.UpdateIssueResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.IssueItem;
      reader.readMessage(value,proto.api.IssueItem.deserializeBinaryFromReader);
      msg.setIssue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateIssueResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateIssueResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateIssueResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateIssueResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIssue();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.IssueItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional IssueItem issue = 1;
 * @return {?proto.api.IssueItem}
 */
proto.api.UpdateIssueResponse.prototype.getIssue = function() {
  return /** @type{?proto.api.IssueItem} */ (
    jspb.Message.getWrapperField(this, proto.api.IssueItem, 1));
};


/**
 * @param {?proto.api.IssueItem|undefined} value
 * @return {!proto.api.UpdateIssueResponse} returns this
*/
proto.api.UpdateIssueResponse.prototype.setIssue = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.UpdateIssueResponse} returns this
 */
proto.api.UpdateIssueResponse.prototype.clearIssue = function() {
  return this.setIssue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UpdateIssueResponse.prototype.hasIssue = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateIssueManagmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateIssueManagmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateIssueManagmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateIssueManagmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    issue: (f = msg.getIssue()) && proto.api.IssueUpdateItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateIssueManagmentRequest}
 */
proto.api.UpdateIssueManagmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateIssueManagmentRequest;
  return proto.api.UpdateIssueManagmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateIssueManagmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateIssueManagmentRequest}
 */
proto.api.UpdateIssueManagmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.api.IssueUpdateItem;
      reader.readMessage(value,proto.api.IssueUpdateItem.deserializeBinaryFromReader);
      msg.setIssue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateIssueManagmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateIssueManagmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateIssueManagmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateIssueManagmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIssue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.IssueUpdateItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.UpdateIssueManagmentRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateIssueManagmentRequest} returns this
 */
proto.api.UpdateIssueManagmentRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional IssueUpdateItem issue = 2;
 * @return {?proto.api.IssueUpdateItem}
 */
proto.api.UpdateIssueManagmentRequest.prototype.getIssue = function() {
  return /** @type{?proto.api.IssueUpdateItem} */ (
    jspb.Message.getWrapperField(this, proto.api.IssueUpdateItem, 2));
};


/**
 * @param {?proto.api.IssueUpdateItem|undefined} value
 * @return {!proto.api.UpdateIssueManagmentRequest} returns this
*/
proto.api.UpdateIssueManagmentRequest.prototype.setIssue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.UpdateIssueManagmentRequest} returns this
 */
proto.api.UpdateIssueManagmentRequest.prototype.clearIssue = function() {
  return this.setIssue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UpdateIssueManagmentRequest.prototype.hasIssue = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateIssueManagmentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateIssueManagmentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateIssueManagmentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateIssueManagmentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    issue: (f = msg.getIssue()) && proto.api.IssueItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateIssueManagmentResponse}
 */
proto.api.UpdateIssueManagmentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateIssueManagmentResponse;
  return proto.api.UpdateIssueManagmentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateIssueManagmentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateIssueManagmentResponse}
 */
proto.api.UpdateIssueManagmentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.IssueItem;
      reader.readMessage(value,proto.api.IssueItem.deserializeBinaryFromReader);
      msg.setIssue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateIssueManagmentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateIssueManagmentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateIssueManagmentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateIssueManagmentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIssue();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.IssueItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional IssueItem issue = 1;
 * @return {?proto.api.IssueItem}
 */
proto.api.UpdateIssueManagmentResponse.prototype.getIssue = function() {
  return /** @type{?proto.api.IssueItem} */ (
    jspb.Message.getWrapperField(this, proto.api.IssueItem, 1));
};


/**
 * @param {?proto.api.IssueItem|undefined} value
 * @return {!proto.api.UpdateIssueManagmentResponse} returns this
*/
proto.api.UpdateIssueManagmentResponse.prototype.setIssue = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.UpdateIssueManagmentResponse} returns this
 */
proto.api.UpdateIssueManagmentResponse.prototype.clearIssue = function() {
  return this.setIssue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UpdateIssueManagmentResponse.prototype.hasIssue = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.IssueManagementUpdateItem.prototype.toObject = function(opt_includeInstance) {
  return proto.api.IssueManagementUpdateItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.IssueManagementUpdateItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.IssueManagementUpdateItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    assignedTo: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.IssueManagementUpdateItem}
 */
proto.api.IssueManagementUpdateItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.IssueManagementUpdateItem;
  return proto.api.IssueManagementUpdateItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.IssueManagementUpdateItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.IssueManagementUpdateItem}
 */
proto.api.IssueManagementUpdateItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.api.IssueStatus.Enum} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssignedTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.IssueManagementUpdateItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.IssueManagementUpdateItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.IssueManagementUpdateItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.IssueManagementUpdateItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAssignedTo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional IssueStatus.Enum status = 1;
 * @return {!proto.api.IssueStatus.Enum}
 */
proto.api.IssueManagementUpdateItem.prototype.getStatus = function() {
  return /** @type {!proto.api.IssueStatus.Enum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.api.IssueStatus.Enum} value
 * @return {!proto.api.IssueManagementUpdateItem} returns this
 */
proto.api.IssueManagementUpdateItem.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string assigned_to = 2;
 * @return {string}
 */
proto.api.IssueManagementUpdateItem.prototype.getAssignedTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IssueManagementUpdateItem} returns this
 */
proto.api.IssueManagementUpdateItem.prototype.setAssignedTo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AddIssueRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AddIssueRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AddIssueRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AddIssueRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerOrgId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    issue: (f = msg.getIssue()) && proto.api.IssueUpdateItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AddIssueRequest}
 */
proto.api.AddIssueRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AddIssueRequest;
  return proto.api.AddIssueRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AddIssueRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AddIssueRequest}
 */
proto.api.AddIssueRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerOrgId(value);
      break;
    case 2:
      var value = new proto.api.IssueUpdateItem;
      reader.readMessage(value,proto.api.IssueUpdateItem.deserializeBinaryFromReader);
      msg.setIssue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AddIssueRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AddIssueRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AddIssueRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AddIssueRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerOrgId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIssue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.IssueUpdateItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string owner_org_id = 1;
 * @return {string}
 */
proto.api.AddIssueRequest.prototype.getOwnerOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AddIssueRequest} returns this
 */
proto.api.AddIssueRequest.prototype.setOwnerOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional IssueUpdateItem issue = 2;
 * @return {?proto.api.IssueUpdateItem}
 */
proto.api.AddIssueRequest.prototype.getIssue = function() {
  return /** @type{?proto.api.IssueUpdateItem} */ (
    jspb.Message.getWrapperField(this, proto.api.IssueUpdateItem, 2));
};


/**
 * @param {?proto.api.IssueUpdateItem|undefined} value
 * @return {!proto.api.AddIssueRequest} returns this
*/
proto.api.AddIssueRequest.prototype.setIssue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.AddIssueRequest} returns this
 */
proto.api.AddIssueRequest.prototype.clearIssue = function() {
  return this.setIssue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.AddIssueRequest.prototype.hasIssue = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AddIssueManagementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AddIssueManagementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AddIssueManagementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AddIssueManagementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerOrgId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    issue: (f = msg.getIssue()) && proto.api.IssueUpdateItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AddIssueManagementRequest}
 */
proto.api.AddIssueManagementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AddIssueManagementRequest;
  return proto.api.AddIssueManagementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AddIssueManagementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AddIssueManagementRequest}
 */
proto.api.AddIssueManagementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerOrgId(value);
      break;
    case 2:
      var value = new proto.api.IssueUpdateItem;
      reader.readMessage(value,proto.api.IssueUpdateItem.deserializeBinaryFromReader);
      msg.setIssue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AddIssueManagementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AddIssueManagementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AddIssueManagementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AddIssueManagementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerOrgId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIssue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.IssueUpdateItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string owner_org_id = 1;
 * @return {string}
 */
proto.api.AddIssueManagementRequest.prototype.getOwnerOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AddIssueManagementRequest} returns this
 */
proto.api.AddIssueManagementRequest.prototype.setOwnerOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional IssueUpdateItem issue = 2;
 * @return {?proto.api.IssueUpdateItem}
 */
proto.api.AddIssueManagementRequest.prototype.getIssue = function() {
  return /** @type{?proto.api.IssueUpdateItem} */ (
    jspb.Message.getWrapperField(this, proto.api.IssueUpdateItem, 2));
};


/**
 * @param {?proto.api.IssueUpdateItem|undefined} value
 * @return {!proto.api.AddIssueManagementRequest} returns this
*/
proto.api.AddIssueManagementRequest.prototype.setIssue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.AddIssueManagementRequest} returns this
 */
proto.api.AddIssueManagementRequest.prototype.clearIssue = function() {
  return this.setIssue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.AddIssueManagementRequest.prototype.hasIssue = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetIssueManagementResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetIssueManagementResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetIssueManagementResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetIssueManagementResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    issue: (f = msg.getIssue()) && proto.api.GetIssueManagementResponse.Item.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetIssueManagementResponse}
 */
proto.api.GetIssueManagementResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetIssueManagementResponse;
  return proto.api.GetIssueManagementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetIssueManagementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetIssueManagementResponse}
 */
proto.api.GetIssueManagementResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.GetIssueManagementResponse.Item;
      reader.readMessage(value,proto.api.GetIssueManagementResponse.Item.deserializeBinaryFromReader);
      msg.setIssue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetIssueManagementResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetIssueManagementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetIssueManagementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetIssueManagementResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIssue();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.GetIssueManagementResponse.Item.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetIssueManagementResponse.Item.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetIssueManagementResponse.Item.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetIssueManagementResponse.Item} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetIssueManagementResponse.Item.toObject = function(includeInstance, msg) {
  var f, obj = {
    basic: (f = msg.getBasic()) && proto.api.IssueItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetIssueManagementResponse.Item}
 */
proto.api.GetIssueManagementResponse.Item.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetIssueManagementResponse.Item;
  return proto.api.GetIssueManagementResponse.Item.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetIssueManagementResponse.Item} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetIssueManagementResponse.Item}
 */
proto.api.GetIssueManagementResponse.Item.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.IssueItem;
      reader.readMessage(value,proto.api.IssueItem.deserializeBinaryFromReader);
      msg.setBasic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetIssueManagementResponse.Item.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetIssueManagementResponse.Item.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetIssueManagementResponse.Item} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetIssueManagementResponse.Item.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBasic();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.IssueItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional IssueItem basic = 1;
 * @return {?proto.api.IssueItem}
 */
proto.api.GetIssueManagementResponse.Item.prototype.getBasic = function() {
  return /** @type{?proto.api.IssueItem} */ (
    jspb.Message.getWrapperField(this, proto.api.IssueItem, 1));
};


/**
 * @param {?proto.api.IssueItem|undefined} value
 * @return {!proto.api.GetIssueManagementResponse.Item} returns this
*/
proto.api.GetIssueManagementResponse.Item.prototype.setBasic = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetIssueManagementResponse.Item} returns this
 */
proto.api.GetIssueManagementResponse.Item.prototype.clearBasic = function() {
  return this.setBasic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetIssueManagementResponse.Item.prototype.hasBasic = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Item issue = 1;
 * @return {?proto.api.GetIssueManagementResponse.Item}
 */
proto.api.GetIssueManagementResponse.prototype.getIssue = function() {
  return /** @type{?proto.api.GetIssueManagementResponse.Item} */ (
    jspb.Message.getWrapperField(this, proto.api.GetIssueManagementResponse.Item, 1));
};


/**
 * @param {?proto.api.GetIssueManagementResponse.Item|undefined} value
 * @return {!proto.api.GetIssueManagementResponse} returns this
*/
proto.api.GetIssueManagementResponse.prototype.setIssue = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetIssueManagementResponse} returns this
 */
proto.api.GetIssueManagementResponse.prototype.clearIssue = function() {
  return this.setIssue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetIssueManagementResponse.prototype.hasIssue = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AddIssueResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AddIssueResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AddIssueResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AddIssueResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    issue: (f = msg.getIssue()) && proto.api.IssueItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AddIssueResponse}
 */
proto.api.AddIssueResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AddIssueResponse;
  return proto.api.AddIssueResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AddIssueResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AddIssueResponse}
 */
proto.api.AddIssueResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.IssueItem;
      reader.readMessage(value,proto.api.IssueItem.deserializeBinaryFromReader);
      msg.setIssue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AddIssueResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AddIssueResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AddIssueResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AddIssueResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIssue();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.IssueItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional IssueItem issue = 1;
 * @return {?proto.api.IssueItem}
 */
proto.api.AddIssueResponse.prototype.getIssue = function() {
  return /** @type{?proto.api.IssueItem} */ (
    jspb.Message.getWrapperField(this, proto.api.IssueItem, 1));
};


/**
 * @param {?proto.api.IssueItem|undefined} value
 * @return {!proto.api.AddIssueResponse} returns this
*/
proto.api.AddIssueResponse.prototype.setIssue = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.AddIssueResponse} returns this
 */
proto.api.AddIssueResponse.prototype.clearIssue = function() {
  return this.setIssue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.AddIssueResponse.prototype.hasIssue = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AddIssueManagementResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AddIssueManagementResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AddIssueManagementResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AddIssueManagementResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    issue: (f = msg.getIssue()) && proto.api.IssueItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AddIssueManagementResponse}
 */
proto.api.AddIssueManagementResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AddIssueManagementResponse;
  return proto.api.AddIssueManagementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AddIssueManagementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AddIssueManagementResponse}
 */
proto.api.AddIssueManagementResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.IssueItem;
      reader.readMessage(value,proto.api.IssueItem.deserializeBinaryFromReader);
      msg.setIssue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AddIssueManagementResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AddIssueManagementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AddIssueManagementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AddIssueManagementResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIssue();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.IssueItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional IssueItem issue = 1;
 * @return {?proto.api.IssueItem}
 */
proto.api.AddIssueManagementResponse.prototype.getIssue = function() {
  return /** @type{?proto.api.IssueItem} */ (
    jspb.Message.getWrapperField(this, proto.api.IssueItem, 1));
};


/**
 * @param {?proto.api.IssueItem|undefined} value
 * @return {!proto.api.AddIssueManagementResponse} returns this
*/
proto.api.AddIssueManagementResponse.prototype.setIssue = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.AddIssueManagementResponse} returns this
 */
proto.api.AddIssueManagementResponse.prototype.clearIssue = function() {
  return this.setIssue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.AddIssueManagementResponse.prototype.hasIssue = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.GetMyIssuesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetMyIssuesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetMyIssuesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetMyIssuesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetMyIssuesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    issuesList: jspb.Message.toObjectList(msg.getIssuesList(),
    proto.api.IssueItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetMyIssuesResponse}
 */
proto.api.GetMyIssuesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetMyIssuesResponse;
  return proto.api.GetMyIssuesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetMyIssuesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetMyIssuesResponse}
 */
proto.api.GetMyIssuesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.IssueItem;
      reader.readMessage(value,proto.api.IssueItem.deserializeBinaryFromReader);
      msg.addIssues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetMyIssuesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetMyIssuesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetMyIssuesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetMyIssuesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIssuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.IssueItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated IssueItem issues = 1;
 * @return {!Array<!proto.api.IssueItem>}
 */
proto.api.GetMyIssuesResponse.prototype.getIssuesList = function() {
  return /** @type{!Array<!proto.api.IssueItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.IssueItem, 1));
};


/**
 * @param {!Array<!proto.api.IssueItem>} value
 * @return {!proto.api.GetMyIssuesResponse} returns this
*/
proto.api.GetMyIssuesResponse.prototype.setIssuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.IssueItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.IssueItem}
 */
proto.api.GetMyIssuesResponse.prototype.addIssues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.IssueItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.GetMyIssuesResponse} returns this
 */
proto.api.GetMyIssuesResponse.prototype.clearIssuesList = function() {
  return this.setIssuesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.GetMyCreatedIssuesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetMyCreatedIssuesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetMyCreatedIssuesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetMyCreatedIssuesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetMyCreatedIssuesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    issuesList: jspb.Message.toObjectList(msg.getIssuesList(),
    proto.api.IssueItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetMyCreatedIssuesResponse}
 */
proto.api.GetMyCreatedIssuesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetMyCreatedIssuesResponse;
  return proto.api.GetMyCreatedIssuesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetMyCreatedIssuesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetMyCreatedIssuesResponse}
 */
proto.api.GetMyCreatedIssuesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.IssueItem;
      reader.readMessage(value,proto.api.IssueItem.deserializeBinaryFromReader);
      msg.addIssues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetMyCreatedIssuesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetMyCreatedIssuesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetMyCreatedIssuesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetMyCreatedIssuesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIssuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.IssueItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated IssueItem issues = 1;
 * @return {!Array<!proto.api.IssueItem>}
 */
proto.api.GetMyCreatedIssuesResponse.prototype.getIssuesList = function() {
  return /** @type{!Array<!proto.api.IssueItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.IssueItem, 1));
};


/**
 * @param {!Array<!proto.api.IssueItem>} value
 * @return {!proto.api.GetMyCreatedIssuesResponse} returns this
*/
proto.api.GetMyCreatedIssuesResponse.prototype.setIssuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.IssueItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.IssueItem}
 */
proto.api.GetMyCreatedIssuesResponse.prototype.addIssues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.IssueItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.GetMyCreatedIssuesResponse} returns this
 */
proto.api.GetMyCreatedIssuesResponse.prototype.clearIssuesList = function() {
  return this.setIssuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.DeleteIssueManagementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.DeleteIssueManagementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.DeleteIssueManagementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteIssueManagementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.DeleteIssueManagementRequest}
 */
proto.api.DeleteIssueManagementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.DeleteIssueManagementRequest;
  return proto.api.DeleteIssueManagementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.DeleteIssueManagementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.DeleteIssueManagementRequest}
 */
proto.api.DeleteIssueManagementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.DeleteIssueManagementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.DeleteIssueManagementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.DeleteIssueManagementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteIssueManagementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.DeleteIssueManagementRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.DeleteIssueManagementRequest} returns this
 */
proto.api.DeleteIssueManagementRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.DeleteIssueManagementResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.DeleteIssueManagementResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.DeleteIssueManagementResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteIssueManagementResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.DeleteIssueManagementResponse}
 */
proto.api.DeleteIssueManagementResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.DeleteIssueManagementResponse;
  return proto.api.DeleteIssueManagementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.DeleteIssueManagementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.DeleteIssueManagementResponse}
 */
proto.api.DeleteIssueManagementResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.DeleteIssueManagementResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.DeleteIssueManagementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.DeleteIssueManagementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteIssueManagementResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UndoDeleteIssueManagementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UndoDeleteIssueManagementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UndoDeleteIssueManagementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UndoDeleteIssueManagementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UndoDeleteIssueManagementRequest}
 */
proto.api.UndoDeleteIssueManagementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UndoDeleteIssueManagementRequest;
  return proto.api.UndoDeleteIssueManagementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UndoDeleteIssueManagementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UndoDeleteIssueManagementRequest}
 */
proto.api.UndoDeleteIssueManagementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UndoDeleteIssueManagementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UndoDeleteIssueManagementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UndoDeleteIssueManagementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UndoDeleteIssueManagementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.UndoDeleteIssueManagementRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UndoDeleteIssueManagementRequest} returns this
 */
proto.api.UndoDeleteIssueManagementRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UndoDeleteIssueManagementResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UndoDeleteIssueManagementResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UndoDeleteIssueManagementResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UndoDeleteIssueManagementResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UndoDeleteIssueManagementResponse}
 */
proto.api.UndoDeleteIssueManagementResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UndoDeleteIssueManagementResponse;
  return proto.api.UndoDeleteIssueManagementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UndoDeleteIssueManagementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UndoDeleteIssueManagementResponse}
 */
proto.api.UndoDeleteIssueManagementResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UndoDeleteIssueManagementResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UndoDeleteIssueManagementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UndoDeleteIssueManagementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UndoDeleteIssueManagementResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ConsigneeItem.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ConsigneeItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ConsigneeItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ConsigneeItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    count: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ConsigneeItem}
 */
proto.api.ConsigneeItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ConsigneeItem;
  return proto.api.ConsigneeItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ConsigneeItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ConsigneeItem}
 */
proto.api.ConsigneeItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ConsigneeItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ConsigneeItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ConsigneeItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ConsigneeItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.ConsigneeItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ConsigneeItem} returns this
 */
proto.api.ConsigneeItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.api.ConsigneeItem.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ConsigneeItem} returns this
 */
proto.api.ConsigneeItem.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.api.ConsigneeItem.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ConsigneeItem} returns this
 */
proto.api.ConsigneeItem.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 count = 4;
 * @return {number}
 */
proto.api.ConsigneeItem.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.ConsigneeItem} returns this
 */
proto.api.ConsigneeItem.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListUsersConsigneeManagementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListUsersConsigneeManagementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListUsersConsigneeManagementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListUsersConsigneeManagementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerOrgId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListUsersConsigneeManagementRequest}
 */
proto.api.ListUsersConsigneeManagementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListUsersConsigneeManagementRequest;
  return proto.api.ListUsersConsigneeManagementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListUsersConsigneeManagementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListUsersConsigneeManagementRequest}
 */
proto.api.ListUsersConsigneeManagementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerOrgId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListUsersConsigneeManagementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListUsersConsigneeManagementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListUsersConsigneeManagementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListUsersConsigneeManagementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerOrgId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string owner_org_id = 1;
 * @return {string}
 */
proto.api.ListUsersConsigneeManagementRequest.prototype.getOwnerOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ListUsersConsigneeManagementRequest} returns this
 */
proto.api.ListUsersConsigneeManagementRequest.prototype.setOwnerOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.ListUsersConsigneeManagementResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListUsersConsigneeManagementResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListUsersConsigneeManagementResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListUsersConsigneeManagementResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListUsersConsigneeManagementResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.api.ListUsersConsigneeManagementResponse.Item.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListUsersConsigneeManagementResponse}
 */
proto.api.ListUsersConsigneeManagementResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListUsersConsigneeManagementResponse;
  return proto.api.ListUsersConsigneeManagementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListUsersConsigneeManagementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListUsersConsigneeManagementResponse}
 */
proto.api.ListUsersConsigneeManagementResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.api.ListUsersConsigneeManagementResponse.Item;
      reader.readMessage(value,proto.api.ListUsersConsigneeManagementResponse.Item.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListUsersConsigneeManagementResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListUsersConsigneeManagementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListUsersConsigneeManagementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListUsersConsigneeManagementResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.api.ListUsersConsigneeManagementResponse.Item.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListUsersConsigneeManagementResponse.Item.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListUsersConsigneeManagementResponse.Item.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListUsersConsigneeManagementResponse.Item} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListUsersConsigneeManagementResponse.Item.toObject = function(includeInstance, msg) {
  var f, obj = {
    basic: (f = msg.getBasic()) && proto.api.ConsigneeItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListUsersConsigneeManagementResponse.Item}
 */
proto.api.ListUsersConsigneeManagementResponse.Item.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListUsersConsigneeManagementResponse.Item;
  return proto.api.ListUsersConsigneeManagementResponse.Item.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListUsersConsigneeManagementResponse.Item} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListUsersConsigneeManagementResponse.Item}
 */
proto.api.ListUsersConsigneeManagementResponse.Item.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.ConsigneeItem;
      reader.readMessage(value,proto.api.ConsigneeItem.deserializeBinaryFromReader);
      msg.setBasic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListUsersConsigneeManagementResponse.Item.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListUsersConsigneeManagementResponse.Item.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListUsersConsigneeManagementResponse.Item} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListUsersConsigneeManagementResponse.Item.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBasic();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.ConsigneeItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional ConsigneeItem basic = 1;
 * @return {?proto.api.ConsigneeItem}
 */
proto.api.ListUsersConsigneeManagementResponse.Item.prototype.getBasic = function() {
  return /** @type{?proto.api.ConsigneeItem} */ (
    jspb.Message.getWrapperField(this, proto.api.ConsigneeItem, 1));
};


/**
 * @param {?proto.api.ConsigneeItem|undefined} value
 * @return {!proto.api.ListUsersConsigneeManagementResponse.Item} returns this
*/
proto.api.ListUsersConsigneeManagementResponse.Item.prototype.setBasic = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListUsersConsigneeManagementResponse.Item} returns this
 */
proto.api.ListUsersConsigneeManagementResponse.Item.prototype.clearBasic = function() {
  return this.setBasic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListUsersConsigneeManagementResponse.Item.prototype.hasBasic = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Item users = 2;
 * @return {!Array<!proto.api.ListUsersConsigneeManagementResponse.Item>}
 */
proto.api.ListUsersConsigneeManagementResponse.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.api.ListUsersConsigneeManagementResponse.Item>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.ListUsersConsigneeManagementResponse.Item, 2));
};


/**
 * @param {!Array<!proto.api.ListUsersConsigneeManagementResponse.Item>} value
 * @return {!proto.api.ListUsersConsigneeManagementResponse} returns this
*/
proto.api.ListUsersConsigneeManagementResponse.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.api.ListUsersConsigneeManagementResponse.Item=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.ListUsersConsigneeManagementResponse.Item}
 */
proto.api.ListUsersConsigneeManagementResponse.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.api.ListUsersConsigneeManagementResponse.Item, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.ListUsersConsigneeManagementResponse} returns this
 */
proto.api.ListUsersConsigneeManagementResponse.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.IssueUnresolved.prototype.toObject = function(opt_includeInstance) {
  return proto.api.IssueUnresolved.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.IssueUnresolved} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.IssueUnresolved.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    type: jspb.Message.getFieldWithDefault(msg, 7, 0),
    vehiclePlate: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.IssueUnresolved}
 */
proto.api.IssueUnresolved.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.IssueUnresolved;
  return proto.api.IssueUnresolved.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.IssueUnresolved} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.IssueUnresolved}
 */
proto.api.IssueUnresolved.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 6:
      var value = /** @type {!proto.api.IssueStatus.Enum} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {!proto.api.IssueType.Enum} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehiclePlate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.IssueUnresolved.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.IssueUnresolved.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.IssueUnresolved} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.IssueUnresolved.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getVehiclePlate();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.IssueUnresolved.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IssueUnresolved} returns this
 */
proto.api.IssueUnresolved.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vehicle_id = 4;
 * @return {string}
 */
proto.api.IssueUnresolved.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IssueUnresolved} returns this
 */
proto.api.IssueUnresolved.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional IssueStatus.Enum status = 6;
 * @return {!proto.api.IssueStatus.Enum}
 */
proto.api.IssueUnresolved.prototype.getStatus = function() {
  return /** @type {!proto.api.IssueStatus.Enum} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.api.IssueStatus.Enum} value
 * @return {!proto.api.IssueUnresolved} returns this
 */
proto.api.IssueUnresolved.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional IssueType.Enum type = 7;
 * @return {!proto.api.IssueType.Enum}
 */
proto.api.IssueUnresolved.prototype.getType = function() {
  return /** @type {!proto.api.IssueType.Enum} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.api.IssueType.Enum} value
 * @return {!proto.api.IssueUnresolved} returns this
 */
proto.api.IssueUnresolved.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string vehicle_plate = 15;
 * @return {string}
 */
proto.api.IssueUnresolved.prototype.getVehiclePlate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IssueUnresolved} returns this
 */
proto.api.IssueUnresolved.prototype.setVehiclePlate = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListUnresolvedDashboardManagementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListUnresolvedDashboardManagementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListUnresolvedDashboardManagementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListUnresolvedDashboardManagementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerOrgId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    duration: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListUnresolvedDashboardManagementRequest}
 */
proto.api.ListUnresolvedDashboardManagementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListUnresolvedDashboardManagementRequest;
  return proto.api.ListUnresolvedDashboardManagementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListUnresolvedDashboardManagementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListUnresolvedDashboardManagementRequest}
 */
proto.api.ListUnresolvedDashboardManagementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerOrgId(value);
      break;
    case 2:
      var value = /** @type {!proto.api.duration.Enum} */ (reader.readEnum());
      msg.setDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListUnresolvedDashboardManagementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListUnresolvedDashboardManagementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListUnresolvedDashboardManagementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListUnresolvedDashboardManagementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerOrgId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string owner_org_id = 1;
 * @return {string}
 */
proto.api.ListUnresolvedDashboardManagementRequest.prototype.getOwnerOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ListUnresolvedDashboardManagementRequest} returns this
 */
proto.api.ListUnresolvedDashboardManagementRequest.prototype.setOwnerOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional duration.Enum duration = 2;
 * @return {!proto.api.duration.Enum}
 */
proto.api.ListUnresolvedDashboardManagementRequest.prototype.getDuration = function() {
  return /** @type {!proto.api.duration.Enum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.api.duration.Enum} value
 * @return {!proto.api.ListUnresolvedDashboardManagementRequest} returns this
 */
proto.api.ListUnresolvedDashboardManagementRequest.prototype.setDuration = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.ListUnresolvedDashboardManagementResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListUnresolvedDashboardManagementResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListUnresolvedDashboardManagementResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListUnresolvedDashboardManagementResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListUnresolvedDashboardManagementResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    issuesList: jspb.Message.toObjectList(msg.getIssuesList(),
    proto.api.ListUnresolvedDashboardManagementResponse.Item.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListUnresolvedDashboardManagementResponse}
 */
proto.api.ListUnresolvedDashboardManagementResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListUnresolvedDashboardManagementResponse;
  return proto.api.ListUnresolvedDashboardManagementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListUnresolvedDashboardManagementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListUnresolvedDashboardManagementResponse}
 */
proto.api.ListUnresolvedDashboardManagementResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.api.ListUnresolvedDashboardManagementResponse.Item;
      reader.readMessage(value,proto.api.ListUnresolvedDashboardManagementResponse.Item.deserializeBinaryFromReader);
      msg.addIssues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListUnresolvedDashboardManagementResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListUnresolvedDashboardManagementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListUnresolvedDashboardManagementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListUnresolvedDashboardManagementResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIssuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.api.ListUnresolvedDashboardManagementResponse.Item.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListUnresolvedDashboardManagementResponse.Item.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListUnresolvedDashboardManagementResponse.Item.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListUnresolvedDashboardManagementResponse.Item} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListUnresolvedDashboardManagementResponse.Item.toObject = function(includeInstance, msg) {
  var f, obj = {
    basic: (f = msg.getBasic()) && proto.api.IssueUnresolved.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListUnresolvedDashboardManagementResponse.Item}
 */
proto.api.ListUnresolvedDashboardManagementResponse.Item.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListUnresolvedDashboardManagementResponse.Item;
  return proto.api.ListUnresolvedDashboardManagementResponse.Item.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListUnresolvedDashboardManagementResponse.Item} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListUnresolvedDashboardManagementResponse.Item}
 */
proto.api.ListUnresolvedDashboardManagementResponse.Item.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.IssueUnresolved;
      reader.readMessage(value,proto.api.IssueUnresolved.deserializeBinaryFromReader);
      msg.setBasic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListUnresolvedDashboardManagementResponse.Item.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListUnresolvedDashboardManagementResponse.Item.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListUnresolvedDashboardManagementResponse.Item} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListUnresolvedDashboardManagementResponse.Item.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBasic();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.IssueUnresolved.serializeBinaryToWriter
    );
  }
};


/**
 * optional IssueUnresolved basic = 1;
 * @return {?proto.api.IssueUnresolved}
 */
proto.api.ListUnresolvedDashboardManagementResponse.Item.prototype.getBasic = function() {
  return /** @type{?proto.api.IssueUnresolved} */ (
    jspb.Message.getWrapperField(this, proto.api.IssueUnresolved, 1));
};


/**
 * @param {?proto.api.IssueUnresolved|undefined} value
 * @return {!proto.api.ListUnresolvedDashboardManagementResponse.Item} returns this
*/
proto.api.ListUnresolvedDashboardManagementResponse.Item.prototype.setBasic = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListUnresolvedDashboardManagementResponse.Item} returns this
 */
proto.api.ListUnresolvedDashboardManagementResponse.Item.prototype.clearBasic = function() {
  return this.setBasic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListUnresolvedDashboardManagementResponse.Item.prototype.hasBasic = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Item issues = 2;
 * @return {!Array<!proto.api.ListUnresolvedDashboardManagementResponse.Item>}
 */
proto.api.ListUnresolvedDashboardManagementResponse.prototype.getIssuesList = function() {
  return /** @type{!Array<!proto.api.ListUnresolvedDashboardManagementResponse.Item>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.ListUnresolvedDashboardManagementResponse.Item, 2));
};


/**
 * @param {!Array<!proto.api.ListUnresolvedDashboardManagementResponse.Item>} value
 * @return {!proto.api.ListUnresolvedDashboardManagementResponse} returns this
*/
proto.api.ListUnresolvedDashboardManagementResponse.prototype.setIssuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.api.ListUnresolvedDashboardManagementResponse.Item=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.ListUnresolvedDashboardManagementResponse.Item}
 */
proto.api.ListUnresolvedDashboardManagementResponse.prototype.addIssues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.api.ListUnresolvedDashboardManagementResponse.Item, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.ListUnresolvedDashboardManagementResponse} returns this
 */
proto.api.ListUnresolvedDashboardManagementResponse.prototype.clearIssuesList = function() {
  return this.setIssuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CountIssuesByStatusManagementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CountIssuesByStatusManagementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CountIssuesByStatusManagementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CountIssuesByStatusManagementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerOrgId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    duration: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CountIssuesByStatusManagementRequest}
 */
proto.api.CountIssuesByStatusManagementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CountIssuesByStatusManagementRequest;
  return proto.api.CountIssuesByStatusManagementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CountIssuesByStatusManagementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CountIssuesByStatusManagementRequest}
 */
proto.api.CountIssuesByStatusManagementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerOrgId(value);
      break;
    case 2:
      var value = /** @type {!proto.api.duration.Enum} */ (reader.readEnum());
      msg.setDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CountIssuesByStatusManagementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CountIssuesByStatusManagementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CountIssuesByStatusManagementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CountIssuesByStatusManagementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerOrgId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string owner_org_id = 1;
 * @return {string}
 */
proto.api.CountIssuesByStatusManagementRequest.prototype.getOwnerOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CountIssuesByStatusManagementRequest} returns this
 */
proto.api.CountIssuesByStatusManagementRequest.prototype.setOwnerOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional duration.Enum duration = 2;
 * @return {!proto.api.duration.Enum}
 */
proto.api.CountIssuesByStatusManagementRequest.prototype.getDuration = function() {
  return /** @type {!proto.api.duration.Enum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.api.duration.Enum} value
 * @return {!proto.api.CountIssuesByStatusManagementRequest} returns this
 */
proto.api.CountIssuesByStatusManagementRequest.prototype.setDuration = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CountIssuesByStatusManagementResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CountIssuesByStatusManagementResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CountIssuesByStatusManagementResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CountIssuesByStatusManagementResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pending: jspb.Message.getFieldWithDefault(msg, 1, 0),
    completed: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cancelled: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CountIssuesByStatusManagementResponse}
 */
proto.api.CountIssuesByStatusManagementResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CountIssuesByStatusManagementResponse;
  return proto.api.CountIssuesByStatusManagementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CountIssuesByStatusManagementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CountIssuesByStatusManagementResponse}
 */
proto.api.CountIssuesByStatusManagementResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPending(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCompleted(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCancelled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CountIssuesByStatusManagementResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CountIssuesByStatusManagementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CountIssuesByStatusManagementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CountIssuesByStatusManagementResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPending();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCompleted();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCancelled();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 pending = 1;
 * @return {number}
 */
proto.api.CountIssuesByStatusManagementResponse.prototype.getPending = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.CountIssuesByStatusManagementResponse} returns this
 */
proto.api.CountIssuesByStatusManagementResponse.prototype.setPending = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 completed = 2;
 * @return {number}
 */
proto.api.CountIssuesByStatusManagementResponse.prototype.getCompleted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.CountIssuesByStatusManagementResponse} returns this
 */
proto.api.CountIssuesByStatusManagementResponse.prototype.setCompleted = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 cancelled = 3;
 * @return {number}
 */
proto.api.CountIssuesByStatusManagementResponse.prototype.getCancelled = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.CountIssuesByStatusManagementResponse} returns this
 */
proto.api.CountIssuesByStatusManagementResponse.prototype.setCancelled = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CountIssuesByTypeManagementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CountIssuesByTypeManagementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CountIssuesByTypeManagementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CountIssuesByTypeManagementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerOrgId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    duration: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CountIssuesByTypeManagementRequest}
 */
proto.api.CountIssuesByTypeManagementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CountIssuesByTypeManagementRequest;
  return proto.api.CountIssuesByTypeManagementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CountIssuesByTypeManagementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CountIssuesByTypeManagementRequest}
 */
proto.api.CountIssuesByTypeManagementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerOrgId(value);
      break;
    case 2:
      var value = /** @type {!proto.api.IssueType.Enum} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {!proto.api.duration.Enum} */ (reader.readEnum());
      msg.setDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CountIssuesByTypeManagementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CountIssuesByTypeManagementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CountIssuesByTypeManagementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CountIssuesByTypeManagementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerOrgId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string owner_org_id = 1;
 * @return {string}
 */
proto.api.CountIssuesByTypeManagementRequest.prototype.getOwnerOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CountIssuesByTypeManagementRequest} returns this
 */
proto.api.CountIssuesByTypeManagementRequest.prototype.setOwnerOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional IssueType.Enum type = 2;
 * @return {!proto.api.IssueType.Enum}
 */
proto.api.CountIssuesByTypeManagementRequest.prototype.getType = function() {
  return /** @type {!proto.api.IssueType.Enum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.api.IssueType.Enum} value
 * @return {!proto.api.CountIssuesByTypeManagementRequest} returns this
 */
proto.api.CountIssuesByTypeManagementRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional duration.Enum duration = 3;
 * @return {!proto.api.duration.Enum}
 */
proto.api.CountIssuesByTypeManagementRequest.prototype.getDuration = function() {
  return /** @type {!proto.api.duration.Enum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.api.duration.Enum} value
 * @return {!proto.api.CountIssuesByTypeManagementRequest} returns this
 */
proto.api.CountIssuesByTypeManagementRequest.prototype.setDuration = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CountIssuesByTypeManagementResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CountIssuesByTypeManagementResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CountIssuesByTypeManagementResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CountIssuesByTypeManagementResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    count: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CountIssuesByTypeManagementResponse}
 */
proto.api.CountIssuesByTypeManagementResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CountIssuesByTypeManagementResponse;
  return proto.api.CountIssuesByTypeManagementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CountIssuesByTypeManagementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CountIssuesByTypeManagementResponse}
 */
proto.api.CountIssuesByTypeManagementResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CountIssuesByTypeManagementResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CountIssuesByTypeManagementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CountIssuesByTypeManagementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CountIssuesByTypeManagementResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 count = 1;
 * @return {number}
 */
proto.api.CountIssuesByTypeManagementResponse.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.CountIssuesByTypeManagementResponse} returns this
 */
proto.api.CountIssuesByTypeManagementResponse.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.duration.prototype.toObject = function(opt_includeInstance) {
  return proto.api.duration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.duration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.duration.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.duration}
 */
proto.api.duration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.duration;
  return proto.api.duration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.duration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.duration}
 */
proto.api.duration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.duration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.duration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.duration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.duration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.api.duration.Enum = {
  DAY: 0,
  WEEK: 1,
  MONTH: 2
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CountIssuesWeeklyManagementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CountIssuesWeeklyManagementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CountIssuesWeeklyManagementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CountIssuesWeeklyManagementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerOrgId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    duration: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CountIssuesWeeklyManagementRequest}
 */
proto.api.CountIssuesWeeklyManagementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CountIssuesWeeklyManagementRequest;
  return proto.api.CountIssuesWeeklyManagementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CountIssuesWeeklyManagementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CountIssuesWeeklyManagementRequest}
 */
proto.api.CountIssuesWeeklyManagementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerOrgId(value);
      break;
    case 2:
      var value = /** @type {!proto.api.duration.Enum} */ (reader.readEnum());
      msg.setDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CountIssuesWeeklyManagementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CountIssuesWeeklyManagementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CountIssuesWeeklyManagementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CountIssuesWeeklyManagementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerOrgId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string owner_org_id = 1;
 * @return {string}
 */
proto.api.CountIssuesWeeklyManagementRequest.prototype.getOwnerOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CountIssuesWeeklyManagementRequest} returns this
 */
proto.api.CountIssuesWeeklyManagementRequest.prototype.setOwnerOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional duration.Enum duration = 2;
 * @return {!proto.api.duration.Enum}
 */
proto.api.CountIssuesWeeklyManagementRequest.prototype.getDuration = function() {
  return /** @type {!proto.api.duration.Enum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.api.duration.Enum} value
 * @return {!proto.api.CountIssuesWeeklyManagementRequest} returns this
 */
proto.api.CountIssuesWeeklyManagementRequest.prototype.setDuration = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.IssuesByType.prototype.toObject = function(opt_includeInstance) {
  return proto.api.IssuesByType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.IssuesByType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.IssuesByType.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    addedAt: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    count: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.IssuesByType}
 */
proto.api.IssuesByType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.IssuesByType;
  return proto.api.IssuesByType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.IssuesByType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.IssuesByType}
 */
proto.api.IssuesByType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.api.IssueType.Enum} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAddedAt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.IssuesByType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.IssuesByType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.IssuesByType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.IssuesByType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAddedAt();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional IssueType.Enum type = 1;
 * @return {!proto.api.IssueType.Enum}
 */
proto.api.IssuesByType.prototype.getType = function() {
  return /** @type {!proto.api.IssueType.Enum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.api.IssueType.Enum} value
 * @return {!proto.api.IssuesByType} returns this
 */
proto.api.IssuesByType.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional double added_at = 2;
 * @return {number}
 */
proto.api.IssuesByType.prototype.getAddedAt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.api.IssuesByType} returns this
 */
proto.api.IssuesByType.prototype.setAddedAt = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int64 count = 3;
 * @return {number}
 */
proto.api.IssuesByType.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.IssuesByType} returns this
 */
proto.api.IssuesByType.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.CountIssuesWeeklyManagementResponse.repeatedFields_ = [1,2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CountIssuesWeeklyManagementResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CountIssuesWeeklyManagementResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CountIssuesWeeklyManagementResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CountIssuesWeeklyManagementResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accidentList: jspb.Message.toObjectList(msg.getAccidentList(),
    proto.api.IssuesByType.toObject, includeInstance),
    breakdownList: jspb.Message.toObjectList(msg.getBreakdownList(),
    proto.api.IssuesByType.toObject, includeInstance),
    checkupList: jspb.Message.toObjectList(msg.getCheckupList(),
    proto.api.IssuesByType.toObject, includeInstance),
    missingList: jspb.Message.toObjectList(msg.getMissingList(),
    proto.api.IssuesByType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CountIssuesWeeklyManagementResponse}
 */
proto.api.CountIssuesWeeklyManagementResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CountIssuesWeeklyManagementResponse;
  return proto.api.CountIssuesWeeklyManagementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CountIssuesWeeklyManagementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CountIssuesWeeklyManagementResponse}
 */
proto.api.CountIssuesWeeklyManagementResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.IssuesByType;
      reader.readMessage(value,proto.api.IssuesByType.deserializeBinaryFromReader);
      msg.addAccident(value);
      break;
    case 2:
      var value = new proto.api.IssuesByType;
      reader.readMessage(value,proto.api.IssuesByType.deserializeBinaryFromReader);
      msg.addBreakdown(value);
      break;
    case 3:
      var value = new proto.api.IssuesByType;
      reader.readMessage(value,proto.api.IssuesByType.deserializeBinaryFromReader);
      msg.addCheckup(value);
      break;
    case 4:
      var value = new proto.api.IssuesByType;
      reader.readMessage(value,proto.api.IssuesByType.deserializeBinaryFromReader);
      msg.addMissing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CountIssuesWeeklyManagementResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CountIssuesWeeklyManagementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CountIssuesWeeklyManagementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CountIssuesWeeklyManagementResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccidentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.IssuesByType.serializeBinaryToWriter
    );
  }
  f = message.getBreakdownList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.api.IssuesByType.serializeBinaryToWriter
    );
  }
  f = message.getCheckupList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.api.IssuesByType.serializeBinaryToWriter
    );
  }
  f = message.getMissingList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.api.IssuesByType.serializeBinaryToWriter
    );
  }
};


/**
 * repeated IssuesByType accident = 1;
 * @return {!Array<!proto.api.IssuesByType>}
 */
proto.api.CountIssuesWeeklyManagementResponse.prototype.getAccidentList = function() {
  return /** @type{!Array<!proto.api.IssuesByType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.IssuesByType, 1));
};


/**
 * @param {!Array<!proto.api.IssuesByType>} value
 * @return {!proto.api.CountIssuesWeeklyManagementResponse} returns this
*/
proto.api.CountIssuesWeeklyManagementResponse.prototype.setAccidentList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.IssuesByType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.IssuesByType}
 */
proto.api.CountIssuesWeeklyManagementResponse.prototype.addAccident = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.IssuesByType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.CountIssuesWeeklyManagementResponse} returns this
 */
proto.api.CountIssuesWeeklyManagementResponse.prototype.clearAccidentList = function() {
  return this.setAccidentList([]);
};


/**
 * repeated IssuesByType breakdown = 2;
 * @return {!Array<!proto.api.IssuesByType>}
 */
proto.api.CountIssuesWeeklyManagementResponse.prototype.getBreakdownList = function() {
  return /** @type{!Array<!proto.api.IssuesByType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.IssuesByType, 2));
};


/**
 * @param {!Array<!proto.api.IssuesByType>} value
 * @return {!proto.api.CountIssuesWeeklyManagementResponse} returns this
*/
proto.api.CountIssuesWeeklyManagementResponse.prototype.setBreakdownList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.api.IssuesByType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.IssuesByType}
 */
proto.api.CountIssuesWeeklyManagementResponse.prototype.addBreakdown = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.api.IssuesByType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.CountIssuesWeeklyManagementResponse} returns this
 */
proto.api.CountIssuesWeeklyManagementResponse.prototype.clearBreakdownList = function() {
  return this.setBreakdownList([]);
};


/**
 * repeated IssuesByType checkup = 3;
 * @return {!Array<!proto.api.IssuesByType>}
 */
proto.api.CountIssuesWeeklyManagementResponse.prototype.getCheckupList = function() {
  return /** @type{!Array<!proto.api.IssuesByType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.IssuesByType, 3));
};


/**
 * @param {!Array<!proto.api.IssuesByType>} value
 * @return {!proto.api.CountIssuesWeeklyManagementResponse} returns this
*/
proto.api.CountIssuesWeeklyManagementResponse.prototype.setCheckupList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.api.IssuesByType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.IssuesByType}
 */
proto.api.CountIssuesWeeklyManagementResponse.prototype.addCheckup = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.api.IssuesByType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.CountIssuesWeeklyManagementResponse} returns this
 */
proto.api.CountIssuesWeeklyManagementResponse.prototype.clearCheckupList = function() {
  return this.setCheckupList([]);
};


/**
 * repeated IssuesByType missing = 4;
 * @return {!Array<!proto.api.IssuesByType>}
 */
proto.api.CountIssuesWeeklyManagementResponse.prototype.getMissingList = function() {
  return /** @type{!Array<!proto.api.IssuesByType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.IssuesByType, 4));
};


/**
 * @param {!Array<!proto.api.IssuesByType>} value
 * @return {!proto.api.CountIssuesWeeklyManagementResponse} returns this
*/
proto.api.CountIssuesWeeklyManagementResponse.prototype.setMissingList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.api.IssuesByType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.IssuesByType}
 */
proto.api.CountIssuesWeeklyManagementResponse.prototype.addMissing = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.api.IssuesByType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.CountIssuesWeeklyManagementResponse} returns this
 */
proto.api.CountIssuesWeeklyManagementResponse.prototype.clearMissingList = function() {
  return this.setMissingList([]);
};


goog.object.extend(exports, proto.api);
