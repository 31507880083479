import { Dispatch, ReactNode } from "react";
import { v4 as uuidV4 } from "uuid";
import { subDays, subMonths, subWeeks } from "date-fns";

import {
  AddIssueManagementRequest,
  ListIssuesManagementRequest,
  IssueUpdateItem,
  IssueType,
  ListIssuesManagementResponse,
  AddIssueManagementResponse,
  DeleteIssueManagementRequest,
  UpdateIssueManagmentRequest,
  UpdateIssueManagmentResponse,
  IssueItemListing,
  ListUsersConsigneeManagementRequest,
  ListUsersConsigneeManagementResponse,
  ConsigneeItem,
  UndoDeleteIssueManagementRequest,
  UndoDeleteIssueManagementResponse,
  CountIssuesByTypeManagementResponse,
  CountIssuesByTypeManagementRequest, KioskIssueType,
} from "protobuf/issue_service_pb";
import { PageRequest, PageResponse } from "protobuf/page_pb";
import {
  CommonActions,
  FilterItem,
  GetState,
  IssuesActions,
  IssuesState,
} from "types/store";
import { issueManagementServiceClient } from "utils/grpc-clients";
import {
  getFullName,
  getSecondsFromDate,
  getSecondsFromMillis,
  getValueArrFromFilterOptions,
} from "utils/helpers";
import { PAGE_SIZE, SUCCESS_MESSAGES } from "utils/constants";
import { Filters } from "protobuf/filter_pb";
import { apiResponse } from "./common-actions";
import { IssueManagementServiceClient } from "protobuf/Issue_serviceServiceClientPb";
import { DeleteUserManagementResponse } from "protobuf/user_service_pb";

function getRange(updatedFilters: FilterItem) {
  const selectedOption = updatedFilters["Time Range"][0];
  const currentDate = new Date();
  const exclusiveTime = getSecondsFromDate(currentDate);
  const last3Days = {
    inclusive: getSecondsFromDate(subDays(currentDate, 3)),
    exclusive: exclusiveTime,
  };
  const lastWeek = {
    inclusive: getSecondsFromDate(subWeeks(currentDate, 1)),
    exclusive: exclusiveTime,
  };
  const lastMonth = {
    inclusive: getSecondsFromDate(subMonths(currentDate, 1)),
    exclusive: exclusiveTime,
  };
  const defaultValues = {
    inclusive: getSecondsFromDate(new Date(0)),
    exclusive: getSecondsFromMillis(currentDate.getTime()),
  };
  const customRange = selectedOption?.range
    ? {
        inclusive: getSecondsFromDate(selectedOption.range[0]),
        exclusive: getSecondsFromDate(selectedOption.range[1]),
      }
    : defaultValues;

  switch (selectedOption["value"]) {
    case "Last 3 days":
      return last3Days;
    case "Last week":
      return lastWeek;
    case "Last month":
      return lastMonth;
    case "Custom Range":
      return customRange;
    default:
      return defaultValues;
  }
}

function getIssuesFilters(updatedFilters: FilterItem, isArchive: boolean) {
  const range = getRange(updatedFilters);

  const issueTypeFilter = new Filters.In();
  const issueIssueFilter = new Filters.In();
  const assigneeFilter = new Filters.In();
  const issueStatusValues = getValueArrFromFilterOptions(
    "Issue Status",
    updatedFilters
  );
  const issueTypeValues = getValueArrFromFilterOptions(
    "Issue Type",
    updatedFilters
  );
  const assigneeValues = getValueArrFromFilterOptions(
    "Assignee",
    updatedFilters
  );

  issueIssueFilter.setStringsList(issueStatusValues);
  issueTypeFilter.setStringsList(issueTypeValues);
  assigneeFilter.setStringsList(assigneeValues);

  const rangeFilter = new Filters.Range();
  rangeFilter.setFromInclusive(Math.round(range.inclusive));
  rangeFilter.setToExclusive(Math.round(range.exclusive));

  const filters = new ListIssuesManagementRequest.Filter();
  filters.setStatus(issueIssueFilter);
  filters.setType(issueTypeFilter);
  filters.setAddedAt(rangeFilter);
  filters.setAssignee(assigneeFilter);
  filters.setIsArchived(isArchive);

  return filters;
}

export function setSelectedIssue(
  payload: IssuesState["selectedIssue"]
): IssuesActions {
  return {
    type: "SET_SELECTED_ISSUE",
    payload,
  };
}

export function setBulkActionsIssues(
  payload: IssuesState["showbulkActionsIssues"]
): IssuesActions {
  return {
    type: "SET_ISSUES_BULK_ACTIONS",
    payload,
  };
}

export function setSelectedIssuesRows(
  payload: IssuesState["selectedIssuesRows"]
): IssuesActions {
  return {
    type: "SET_ISSUES_SELECTED_ROWS",
    payload,
  };
}

export function setIssues(payload: IssuesState["issues"]): IssuesActions {
  return {
    type: "SET_ISSUES",
    payload: payload,
  };
}

export function setIssuesPage(payload: PageResponse.AsObject): IssuesActions {
  return {
    type: "SET_ISSUES_TOTAL_PAGE",
    payload,
  };
}

export function setIssuesSelectedPage(payload: number): IssuesActions {
  return {
    type: "SET_ISSUES_SELECTED_PAGE",
    payload,
  };
}

export function updateIssuesFilters(
  payload: IssuesState["filters"]["updated"]
): IssuesActions {
  return {
    type: "SET_ISSUES_UPDATED_FILTERS",
    payload,
  };
}

export function setIssuesListType(
  payload: IssuesState["filters"]["listType"]
): IssuesActions {
  return {
    type: "SET_ISSUES_LIST_TYPE",
    payload,
  };
}

export function initializeIssues(
  pageNo: number = 1,
  updatedFilters: FilterItem,
  listType: IssuesState["filters"]["listType"]
) {
  const page = new PageRequest();
  page.setPageSize(PAGE_SIZE);
  page.setPageNumber(pageNo);

  const request = new ListIssuesManagementRequest();
  request.setPage(page);
  request.setFilter(getIssuesFilters(updatedFilters, listType === "archive"));
  console.log("request : ", request.toObject())
  console.log("Issue in initialize issue ");
  return (dispatch: Dispatch<any>) => {
    function dispatcher(resp: ListIssuesManagementResponse) {
      const page = resp?.getPage()?.toObject() || {
        totalElements: 0,
        totalPages: 0,
      };
      const issuesList = resp
        ?.getIssuesList()
        ?.map(
          (issue) => issue.getBasic()?.toObject() as IssueItemListing.AsObject
        );

      dispatch(setIssuesPage(page));
      dispatch(setIssues(issuesList));
    }

    dispatch(
      apiResponse<
        IssueManagementServiceClient,
        ListIssuesManagementRequest,
        ListIssuesManagementResponse
      >({
        client: issueManagementServiceClient,
        action: "listIssues",
        request,
        dispatcher,
      })
    );
  };
}

export function addIssue({
  assigneeId = uuidV4(),
  type = IssueType.Enum.ACCIDENT,
  vehicleId = "9c908399-1f50-4665-8868-3a03dfc6b093",
  dueDate,
    issue_occur_date,
  riderId,issue_host_id,
  kiosk_issue_type = KioskIssueType.Enum.UNKNOWN,
  type_description, issue_host,
}: {
  assigneeId: string;
  type: number;
  vehicleId: string;
  dueDate: Date;
  issue_occur_date: Date;
  riderId: string;
  issue_host_id:string,
  kiosk_issue_type: number,
  type_description:string,
  issue_host:number,

}) {
  return (dispatch: Dispatch<any>, getState: GetState) => {
    const {
      issues: { page, filters },
    } = getState();
    const issue = new IssueUpdateItem();
    issue.setAssignedTo(assigneeId);
    issue.setType(type);
    issue.setVehicleId(vehicleId);
    issue.setDueDate(Math.round(getSecondsFromDate(dueDate)));
    issue.setRiderId(riderId);
    issue.setIssueHost(issue_host)
    issue.setIssueHostId(issue_host_id)
    issue.setKioskIssueType(kiosk_issue_type)
    issue.setIssueOtherTypeText(type_description)
    issue.setIssueOccurDate(Math.round(getSecondsFromDate(issue_occur_date)))

    const request = new AddIssueManagementRequest();
    request.setIssue(issue);

    dispatch(
      apiResponse<
        IssueManagementServiceClient,
        AddIssueManagementRequest,
        AddIssueManagementResponse
      >({
        client: issueManagementServiceClient,
        action: "addIssue",
        request,
        successMessage: SUCCESS_MESSAGES.addIssue,
        dispatcher: () => {
          dispatch(
            initializeIssues(
              page.selectedPage,
              filters.updated,
              filters.listType
            )
          );
        },
      })
    );
  };
}

export function onChangeIssuesFilters(
  name: string,
  value: string[],
  type: "all" | "list"
) {
  return (
    dispatch: Dispatch<IssuesActions | CommonActions>,
    getState: GetState
  ) => {
    const {
      issues: {
        filters: { original, updated },
      },
    } = getState();
    if (type === "list") {
      const filteredValues = original[name].filter((filter) =>
        value.includes(filter.value)
      );
      const updatedFilters = { ...updated, [name]: filteredValues };
      dispatch(updateIssuesFilters(updatedFilters));
    } else {
      const updatedFilters = {
        ...updated,
        [name]: value ? original[name] : [],
      };
      dispatch(updateIssuesFilters(updatedFilters));
    }
  };
}

export function onChangeTimeRangeIssuesFilters(
  value: string,
  range?: [Date, Date]
) {
  return (
    dispatch: Dispatch<IssuesActions | CommonActions>,
    getState: GetState
  ) => {
    const {
      issues: {
        filters: { original, updated },
      },
    } = getState();
    const selectedTime = original["Time Range"]?.find(
      (option) => option.value === value
    );
    const updateFilters = {
      ...updated,
      "Time Range": selectedTime ? [{ ...selectedTime, range }] : [],
    };

    dispatch(updateIssuesFilters(updateFilters));
  };
}

export function onChangeRootIssuesFilters(checked?: boolean) {
  return (
    dispatch: Dispatch<IssuesActions | CommonActions>,
    getState: GetState
  ) => {
    const {
      issues: {
        filters: { original, updated },
      },
    } = getState();

    let updateFilters = {};

    if (checked) {
      updateFilters = {
        ...updated,
        "Issue Status": original["Issue Status"],
        "Issue Type": original["Issue Type"],
        Assignee: original["Assignee"],
      };
    } else {
      updateFilters = {
        ...updated,
        "Issue Status": [],
        "Time Range": [{ label: "Last month", value: "Last month" }],
        "Issue Type": [],
        Assignee: [],
      };
    }

    dispatch(updateIssuesFilters(updateFilters));
  };
}

export function setIssuessSearch(
  payload: IssuesState["search"]
): IssuesActions {
  return {
    type: "SET_ISSUES_SEARCH",
    payload,
  };
}

export function editIssue(
  {
    assigneeId,
    type,
    vehicleId,
    dueDate,
    riderId,
    status,
    issue_host_id,
    kiosk_issue_type,
    type_description,
    issue_host,
      issue_level,
  }: {
    assigneeId: string;
    type: number;
    vehicleId: string;
    dueDate: Date;
    riderId: string;
    status: number;
    issue_host_id:string,
    kiosk_issue_type: number,
    type_description:string,
    issue_host:number,
    issue_level:number,
  },
  id: string
) {
  return (dispatch: Dispatch<any>, getState: GetState) => {
    const {
      issues: { page, filters },
    } = getState();
    const issue = new IssueUpdateItem();
    issue.setAssignedTo(assigneeId);
    issue.setRiderId(riderId);
    issue.setType(type);
    issue.setVehicleId(vehicleId);
    issue.setDueDate(Math.round(getSecondsFromDate(dueDate)));
    issue.setIssueHost(issue_host)
    issue.setIssueHostId(issue_host_id)
    issue.setKioskIssueType(kiosk_issue_type)
    issue.setIssueOtherTypeText(type_description)
    issue.setIssueLevel(issue_level)
    issue.setStatus(status)

    const request = new UpdateIssueManagmentRequest();
    request.setIssue(issue);
    request.setId(id);

    dispatch(
      apiResponse<
        IssueManagementServiceClient,
        UpdateIssueManagmentRequest,
        UpdateIssueManagmentResponse
      >({
        client: issueManagementServiceClient,
        action: "updateIssue",
        request,
        successMessage: SUCCESS_MESSAGES.updateIssue,
        dispatcher: () => {
          dispatch(
            initializeIssues(
              page.selectedPage,
              filters.updated,
              filters.listType
            )
          );
        },
      })
    );
  };
}

export function deleteIssue(issueId: string) {
  return (dispatch: Dispatch<any>, getState: GetState) => {
    const {
      issues: {
        page: { selectedPage },
        filters: { updated, listType },
      },
    } = getState();

    const request = new DeleteIssueManagementRequest();
    request.setId(issueId);

    function dispatcher() {
      dispatch(initializeIssues(selectedPage, updated, listType));
    }

    dispatch(
      apiResponse<
        IssueManagementServiceClient,
        DeleteIssueManagementRequest,
        DeleteUserManagementResponse
      >({
        request,
        dispatcher,
        client: issueManagementServiceClient,
        action: "deleteIssues",
        successMessage: SUCCESS_MESSAGES.deleteIssue,
      })
    );
  };
}

export function undoDeleteIssue(issueId: string) {
  return (dispatch: Dispatch<any>, getState: GetState) => {
    const {
      issues: {
        page: { selectedPage },
        filters: { updated, listType },
      },
    } = getState();

    const request = new UndoDeleteIssueManagementRequest();
    request.setId(issueId);

    function dispatcher() {
      dispatch(initializeIssues(selectedPage, updated, listType));
    }

    dispatch(
      apiResponse<
        IssueManagementServiceClient,
        UndoDeleteIssueManagementRequest,
        UndoDeleteIssueManagementResponse
      >({
        request,
        dispatcher,
        client: issueManagementServiceClient,
        action: "undoDeleteIssues",
        successMessage: SUCCESS_MESSAGES.undoDeleteIssue,
      })
    );
  };
}

export function setIssuesOriginalFilters(
  payload: IssuesState["filters"]["original"]
): IssuesActions {
  return {
    type: "SET_ISSUES_ORIGINAL_FILTERS",
    payload,
  };
}

export function setAssigneeFilter() {
  return (dispatch: Dispatch<any>, getState: GetState) => {
    const { original } = getState().issues.filters;
    const request = new ListUsersConsigneeManagementRequest();

    function dispatcher(resp: ListUsersConsigneeManagementResponse) {
      const list = resp
        .getUsersList()
        .map((user) => user.getBasic()?.toObject() as ConsigneeItem.AsObject);
      const assigneeFilters = list.map((assignee) => {
        const fullName = getFullName(assignee.firstName, assignee.lastName);
        return {
          label: fullName,
          value: assignee.id,
          render: assignee.count as ReactNode,
        };
      });

      dispatch(
        setIssuesOriginalFilters({ ...original, Assignee: assigneeFilters })
      );
    }

    dispatch(
      apiResponse<
        IssueManagementServiceClient,
        ListUsersConsigneeManagementRequest,
        ListUsersConsigneeManagementResponse
      >({
        client: issueManagementServiceClient,
        action: "listConsignees",
        request,
        dispatcher,
      })
    );
  };
}

export function setIssueCount(
  type: IssueType.Enum,
  dispatcher: (response: CountIssuesByTypeManagementResponse) => void
) {
  return (dispatch: Dispatch<any>) => {
    const request = new CountIssuesByTypeManagementRequest();
    request.setType(type);
    request.setDuration(2);

    dispatch(
      apiResponse<
        IssueManagementServiceClient,
        CountIssuesByTypeManagementRequest,
        CountIssuesByTypeManagementResponse
      >({
        request,
        action: "dashboardCounts",
        dispatcher,
        client: issueManagementServiceClient,
      })
    );
  };
}
