import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TableProps } from "antd/lib/table";
import { Table, TablePagination } from "components/base";

import {
  UserIconCellTable,
  safetyRating,
  ActionMenu,
  assignedVehicles,
  userStatus,
} from "./cell-renders";
import { useGetUserData } from "utils/hooks";
import { parseUsersTable } from "utils/parsers";
import {dispatchUsers, setSelectedTab} from "store/actions";
import { AppState } from "store";
import { UserTabName } from "types";
import {
  setBulkActionsUser,
  setSelectedUserRows,
} from "store/actions/users-actions";
const allColumns: TableProps<any>["columns"] = [
  {
    title: "Name",
    dataIndex: "driver_name",
    render: UserIconCellTable,
    width: 140,
    ellipsis: true,
  },
  // {
  //   title: "Document ID",
  //   dataIndex: "document_id",
  // },
  {
    title: "Phone Number",
    dataIndex: "phone_no",
    width: 100,
    ellipsis: true,
  },
  {
    title: "Role",
    dataIndex: "role",
    width: 140,
    ellipsis: true,
  },
  {
    title: "Added On",
    dataIndex: "added_on",
    width: 130,
    ellipsis: true,
  },
  {
    title: "Added By",
    dataIndex: "added_by",
    width: 140,
    ellipsis: true,
  },
  // {
  //   title: "Status",
  //   dataIndex: "user_status",
  //   width: 140,
  //   ellipsis: true,
  //   render: userStatus,
  // },
  {
    title: "Organization",
    dataIndex: "organization_name",
    width: 140,
    ellipsis: true,
  },

  {
    title: " ",
    dataIndex: "action",
    render: ActionMenu,
    align: "right",
    width: 56,
    className: "action-menu open-link --user",
  },
];
const adminsColumns: TableProps<any>["columns"] = [
  {
    title: "Admin Name",
    dataIndex: "driver_name",
    render: UserIconCellTable,
    width: 120,
    ellipsis: true,
  },

  {
    title: "Phone Number",
    dataIndex: "phone_no",
    width: 120,
  },
  {
    title: "Organization",
    dataIndex: "organization_name",
    width: 140,
    ellipsis: true,
  },
  {
    title: "Added On",
    dataIndex: "added_on",
    width: 120,
  },
  {
    title: "Added By",
    dataIndex: "added_by",
    width: 120,
  },

  {
    title: " ",
    dataIndex: "action",
    render: ActionMenu,
    align: "right",
    width: 56,
    className: "action-menu open-link --user",
  },
];
const ridersColumns: TableProps<any>["columns"] = [
  {
    title: "Rider Name",
    dataIndex: "driver_name",
    render: UserIconCellTable,
    width: 140,
    ellipsis: true,
  },

  {
    title: "Assigned Vehicles",
    dataIndex: "vehicle_id",
    render: assignedVehicles,
    width: 120,
    ellipsis: true,
  },
 
{
  title: "Phone Number",
  dataIndex: "phone_no",
  width: 100,
  ellipsis: true,
},
{
  title: "Added On",
  dataIndex: "added_on",
  width: 100,
  ellipsis: true,
},
{
  title: "Added By",
  dataIndex: "added_by",
  width: 140,
  ellipsis: true,
},
  {
    title: " ",
    dataIndex: "action",
    render: ActionMenu,
    align: "right",
    width: 56,
    className: "action-menu open-link --user",
  },
];
// const mechanicsColumns: TableProps<any>["columns"] = [
//   {
//     title: "Mechanic Name",
//     dataIndex: "driver_name",
//     render: UserIconCellTable,
//     width: 120,
//     ellipsis: true,
//   },
//
//   {
//     title: "Assigned Vehicles",
//     dataIndex: "vehicle_id",
//     render: assignedVehicles,
//     width: 120,
//   },
//   {
//     title: "Today’s Shifts",
//     dataIndex: "today_shift",
//     width: 120,
//   },
//   {
//     title: "Active Tasks",
//     dataIndex: "active_tasks",
//     align: "center",
//     width: 70,
//   },
//   {
//     title: "Tasks Completed ",
//     dataIndex: "task_completed",
//     align: "center",
//     width: 100,
//   },
//   {
//     title: " ",
//     dataIndex: "action",
//     render: ActionMenu,
//     align: "right",
//     width: 56,
//     className: "action-menu open-link --user",
//   },
// ];

function renderColumns(selectedTab: UserTabName, userType:number) {
  switch (selectedTab) {
    case "all":
      let allCols = allColumns
      if (userType > 2) {
        allCols = allColumns?.filter(item => {
          return item.title !== "Organization"
        })
      }
      return allCols;
    case "admins":
      return adminsColumns;
    case "client admins":
      return adminsColumns;
    case "riders":
      return ridersColumns;
    // case "mechanics":
    //   return mechanicsColumns;
    default:
      return allColumns;
  }
}

export default function TableView() {
  const dispatch = useDispatch();
  const { selectedTab, search, selectedUserRows } = useSelector<
    AppState,
    AppState["users"]
  >((state) => state.users);
  const {
    common: { userType= 1 }
  } = useSelector<AppState, AppState>((state) => state);

  const onChangeSelection = useCallback(
    (value) => {
      dispatch(setSelectedUserRows(value));

      if (value.length !== 0) {
        dispatch(setBulkActionsUser(true));
      } else {
        dispatch(setBulkActionsUser(false));
      }
    },
    [dispatch]
  );

  const { page, onNext, onBack, tableData, resetCallback } =
    useGetUserData(selectedTab);
  const dataSource = useMemo(() => {
    return parseUsersTable(search, tableData);
  }, [search, tableData]);

  useEffect(() => {
    if (userType == 6) {
       dispatch(setSelectedTab("riders"))
    }
    console.log("selected tab : ", selectedTab)
    dispatchUsers(dispatch, selectedTab);
  }, [dispatch, selectedTab, page.selectedPage]);

  return (
    <>
      <Table
        customClasses="user-table"
        columns={renderColumns(selectedTab, userType)}
        dataSource={dataSource}
        // rowSelection={{
        //   selectedRowKeys: selectedUserRows,
        //   onChange: onChangeSelection,
        // }}
        pagination={false}
        scroll={{ y: "65vh" }}
      />
      <TablePagination
        page={page}
        onBack={onBack}
        onNext={onNext}
        resetCallback={resetCallback}
      />
    </>
  );
}
