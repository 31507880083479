import {BatteryState, FcmNotificationState} from "../../types";

export function parseFcmNotificationTable(
    list?: FcmNotificationState["notifications"]
) {
    if (list) {
        const data = list.map((notification, index) => ({
            key: index,
            id: notification.id,
            title: notification.title,
            body: notification.body,
            is_read: notification.isRead,
            notification_time: notification.notificationTime,
            action: "",
            raw: notification,
        }));
        return data
    }

    return [];
}