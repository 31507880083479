/**
 * @fileoverview gRPC-Web generated client stub for api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v4.24.2
// source: fcm_notification_service.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as fcm_notification_service_pb from './fcm_notification_service_pb';


export class FcmNotificationServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetUnreadNotification = new grpcWeb.MethodDescriptor(
    '/api.FcmNotificationService/GetUnreadNotification',
    grpcWeb.MethodType.UNARY,
    fcm_notification_service_pb.GetUnreadNotificationRequest,
    fcm_notification_service_pb.GetUnreadNotificationResponse,
    (request: fcm_notification_service_pb.GetUnreadNotificationRequest) => {
      return request.serializeBinary();
    },
    fcm_notification_service_pb.GetUnreadNotificationResponse.deserializeBinary
  );

  getUnreadNotification(
    request: fcm_notification_service_pb.GetUnreadNotificationRequest,
    metadata: grpcWeb.Metadata | null): Promise<fcm_notification_service_pb.GetUnreadNotificationResponse>;

  getUnreadNotification(
    request: fcm_notification_service_pb.GetUnreadNotificationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: fcm_notification_service_pb.GetUnreadNotificationResponse) => void): grpcWeb.ClientReadableStream<fcm_notification_service_pb.GetUnreadNotificationResponse>;

  getUnreadNotification(
    request: fcm_notification_service_pb.GetUnreadNotificationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: fcm_notification_service_pb.GetUnreadNotificationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.FcmNotificationService/GetUnreadNotification',
        request,
        metadata || {},
        this.methodDescriptorGetUnreadNotification,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.FcmNotificationService/GetUnreadNotification',
    request,
    metadata || {},
    this.methodDescriptorGetUnreadNotification);
  }

  methodDescriptorMarkReadNotificationsByIds = new grpcWeb.MethodDescriptor(
    '/api.FcmNotificationService/MarkReadNotificationsByIds',
    grpcWeb.MethodType.UNARY,
    fcm_notification_service_pb.MarkReadNotificationsByIdsRequest,
    fcm_notification_service_pb.MarkReadNotificationsByIdsResponse,
    (request: fcm_notification_service_pb.MarkReadNotificationsByIdsRequest) => {
      return request.serializeBinary();
    },
    fcm_notification_service_pb.MarkReadNotificationsByIdsResponse.deserializeBinary
  );

  markReadNotificationsByIds(
    request: fcm_notification_service_pb.MarkReadNotificationsByIdsRequest,
    metadata: grpcWeb.Metadata | null): Promise<fcm_notification_service_pb.MarkReadNotificationsByIdsResponse>;

  markReadNotificationsByIds(
    request: fcm_notification_service_pb.MarkReadNotificationsByIdsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: fcm_notification_service_pb.MarkReadNotificationsByIdsResponse) => void): grpcWeb.ClientReadableStream<fcm_notification_service_pb.MarkReadNotificationsByIdsResponse>;

  markReadNotificationsByIds(
    request: fcm_notification_service_pb.MarkReadNotificationsByIdsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: fcm_notification_service_pb.MarkReadNotificationsByIdsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.FcmNotificationService/MarkReadNotificationsByIds',
        request,
        metadata || {},
        this.methodDescriptorMarkReadNotificationsByIds,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.FcmNotificationService/MarkReadNotificationsByIds',
    request,
    metadata || {},
    this.methodDescriptorMarkReadNotificationsByIds);
  }

  methodDescriptorGetNotificationForUser = new grpcWeb.MethodDescriptor(
    '/api.FcmNotificationService/GetNotificationForUser',
    grpcWeb.MethodType.UNARY,
    fcm_notification_service_pb.GetNotificationForUserRequest,
    fcm_notification_service_pb.GetNotificationForUserResponse,
    (request: fcm_notification_service_pb.GetNotificationForUserRequest) => {
      return request.serializeBinary();
    },
    fcm_notification_service_pb.GetNotificationForUserResponse.deserializeBinary
  );

  getNotificationForUser(
    request: fcm_notification_service_pb.GetNotificationForUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<fcm_notification_service_pb.GetNotificationForUserResponse>;

  getNotificationForUser(
    request: fcm_notification_service_pb.GetNotificationForUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: fcm_notification_service_pb.GetNotificationForUserResponse) => void): grpcWeb.ClientReadableStream<fcm_notification_service_pb.GetNotificationForUserResponse>;

  getNotificationForUser(
    request: fcm_notification_service_pb.GetNotificationForUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: fcm_notification_service_pb.GetNotificationForUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.FcmNotificationService/GetNotificationForUser',
        request,
        metadata || {},
        this.methodDescriptorGetNotificationForUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.FcmNotificationService/GetNotificationForUser',
    request,
    metadata || {},
    this.methodDescriptorGetNotificationForUser);
  }

}

