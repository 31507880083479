//initialize battery heartbeat
import {BatteryHBItem, GetBatteryHBRequest, GetBatteryHBResponse} from "../../protobuf/battery_service_pb";
import {BatteryActions, BatteryHeartbeat, BatteryHeartbeatAction} from "../../types";
import {Dispatch} from "react";
import {apiResponse} from "./common-actions";
import {BatteryManagementServiceClient} from "../../protobuf/Battery_serviceServiceClientPb";
import {batteryManagmentServiceClient} from "../../utils/grpc-clients";
import {useDispatch} from "react-redux";

export function initializeBatteriesHeartbeat({
    id,}: { id:string}){

    const request = new GetBatteryHBRequest()
    request.setBatteryId(id)

    return async (dispatch: Dispatch<any>) => {
        function dispatcher(resp: GetBatteryHBResponse) {
            const batteryHB: BatteryHBItem[] = resp?.getBatteryHeartbeatsList();
            
            dispatch(setBatteryHB(batteryHB))
        }
        dispatch(
            apiResponse<
                BatteryManagementServiceClient,
                GetBatteryHBRequest,
                GetBatteryHBResponse
            >({
                client: batteryManagmentServiceClient,
                action: "getBatteryHB",
                request,
                dispatcher,
            })
        );
    };
}
//end battery heartbeat


export function setBatteryHB(
    payload: BatteryHBItem[]
): BatteryActions {
    return {
        type: "SET_BATTERIES_HB",
        payload,
    }
}