import { useCallback, useEffect } from "react";
import { Breadcrumb, Button, Rate, Tooltip, Typography } from "antd";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { UserTile } from "components";
import { Table, Tag } from "components/base";
import {
  getDownloadedBytes,
  setModalName,
  setSelectedUser,
} from "store/actions";
import {
  backIcon,
  phoneIcon,
  mailIcon,
  cameraIcon,
  halmetIcon,
  distanceIcon,
  ratingIcon,
  bikeIcon,
  userDetailClockIcon,
  checkIcon,
  clipboardIcon,
  editIcon,
  holdIcon,
  efficiencyIcon,
  uploadedFileIcon,
  downloadFileIcon,
} from "svgs";
import { AppState } from "store";
import { UsersState } from "types";
import { USER_TYPE } from "utils/mapped-data";
import { getMockLocation } from "components/tab-content/users-content/__mock__";
import { getHref } from "utils/helpers";
import { DEFAULT_AVATER } from "utils/constants";

const { Title } = Typography;

const columns = [
  {
    title: "title",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Value",
    dataIndex: "value",
    key: "value",
  },
];

const getData = (user?: UsersState["selectedUser"]) => {
  const href = getHref(user?.document, user?.documentType);
  const allUserData = [
    {
      key: "1",
      title: "Document ID",
      value: user?.documentId,
    },
    {
      key: "2",
      title: "Role",
      value: user?.type ? USER_TYPE[user?.type] : "",
    },
    {
      key: "3",
      title: "Assigned Vehicles",
      value: user?.vehicle ? (
        user.vehicle?.map((v) => (
          <Tag customClasses="user-tag vehicle-id-tag">
            {v.vehicleLicensePlate}
          </Tag>
        ))
      ) : (
        <span />
      ),
    },
    {
      key: "4",
      title: "Assigned Zone",
      value: "Zone Area A",
    },
    // {
    //   key: "5",
    //   title: "Rider's Pickup Location",
    //   value: getMockLocation(user?.vehicle?.[0]?.vehicleId?.toString() || ""),
    // },
    {
      key: "6",
      title: "License Number",
      value: user?.lincenceNo,
    },
    {
      key: "7",
      title: "Document Upload",
      value: user?.documentName ? (
        <Tooltip title={user?.documentName} className="uploadded-file">
          <span className="file-icon">{uploadedFileIcon}</span>
          <section className="file-info">
            <span className="--name">{user?.documentName}</span>
            <span className="--size"></span>
          </section>
          <a
            href={href}
            download={user?.documentName}
            className="download-file-icon"
          >
            {downloadFileIcon}
          </a>
        </Tooltip>
      ) : (
        ""
      ),
    },
  ];

  return user?.type !== 3
    ? allUserData.filter((data) => data.key !== "5")
    : allUserData;
};

const generalStats = [
  {
    icon: <span className="--niagara">{halmetIcon}</span>,
    value: <span className="value">4.0</span>,
    title: "Safety Rating",
  },
  {
    icon: <span className="--orange">{distanceIcon}</span>,
    value: (
      <section>
        <span className="value">240 </span>
        <span className="unit">kms.</span>
      </section>
    ),
    title: "Total Driving Distance",
  },
  {
    icon: <span>{efficiencyIcon}</span>,
    value: <span className="value">4.5</span>,
    title: "Effeciency Rating",
  },
  {
    icon: <span className="--yellow">{ratingIcon}</span>,
    value: <span className="value">4.6</span>,
    title: "Vehicle Care Rating",
  },
  {
    icon: <span className="--blue">{bikeIcon}</span>,
    value: <span className="value">36</span>,
    title: "Total Shifts Performed",
  },
  {
    icon: <span className="--blue">{userDetailClockIcon}</span>,
    value: (
      <section>
        <span className="value">53:36 </span>
        <span className="unit">hrs</span>
      </section>
    ),
    title: "Total Driving Time",
  },
];

const tasks = [
  {
    icon: <span className="--sea-green">{checkIcon}</span>,
    value: <span className="value">300</span>,
    title: "Total Tasks",
    link: (
      <a href="" className="link ">
        View
      </a>
    ),
  },
  {
    icon: <span className="--purple">{clipboardIcon}</span>,
    value: <span className="value">03</span>,
    title: "Active Tasks",
    link: (
      <a href="" className="link ">
        View
      </a>
    ),
  },
  {
    icon: <span className="--yellow">{clipboardIcon}</span>,
    value: (
      <section>
        <span className="value">1,200 </span>
        <span className="till-date">- TILL DATE</span>
      </section>
    ),
    title: "Total Tasks Assigned",
  },
];

export default function UserDetail() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { selectedUser } = useSelector<AppState, AppState["users"]>(
    (state) => state.users
  );
  const {
    common: { userType= 1 }
  } = useSelector<AppState, AppState>((state) => state);
  const onClickBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const onEditUser = useCallback(() => {
    dispatch(setModalName("edit_user"));
  }, [dispatch]);

  const onUploadPic = useCallback(() => {
    dispatch(setModalName("upload_pic"));
  }, [dispatch]);

  const downloadFile = useCallback(() => {
    if (selectedUser?.documentLink) {
      dispatch(
        getDownloadedBytes(selectedUser.documentLink, (resp) => {
          dispatch(
            setSelectedUser({ ...selectedUser, document: resp.getFileData() })
          );
        })
      );
    }
  }, [dispatch, selectedUser]);

  useEffect(() => {
    downloadFile();
  }, [downloadFile, selectedUser?.documentLink]);

  const onArchive = useCallback(() => {
    dispatch(setModalName("block_account"));
  }, [dispatch]);

  return (
    <>
      <header className="user-detail-header">
        <section>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Button
                type="link"
                className="breadcrumb-active"
                onClick={onClickBack}
              >
                Users
              </Button>
            </Breadcrumb.Item>
            <Breadcrumb.Item>User Detail</Breadcrumb.Item>
          </Breadcrumb>
        </section>
        {userType != 6 && <section className="header-actions">
          <Button onClick={onEditUser}>{editIcon} Edit</Button>
          <Button type="primary" danger onClick={onArchive}>
            {holdIcon} Block Account
          </Button>
        </section> }
      </header>
      <main className="user-detail-content">
        <Button type="link" onClick={onClickBack} className="--back-btn">
          {backIcon}
        </Button>
        <section className="profile">
          <figure className="--pic-container">
            <img src={selectedUser?.imgUrl || DEFAULT_AVATER} alt="user pic" />
            <figcaption className="--update-pic" onClick={onUploadPic}>
              {cameraIcon}
            </figcaption>
          </figure>
          <article className="user">
            <Title className="--name">{selectedUser?.name}</Title>
            <span className="--contact-info">
              <span className="--icon">{phoneIcon}</span> {selectedUser?.phone}
            </span>
            <span className="--contact-info">
              <span className="--icon">{mailIcon}</span> {selectedUser?.email}
            </span>

            <section className="performance">
              {/*<Title className="rating">4.2</Title>*/}
              {/*<figure className="rating-info">*/}
              {/*  <Rate allowHalf defaultValue={4.2} className="stars" />*/}
              {/*  <figcaption className="caption">Overall Performance</figcaption>*/}
              {/*</figure>*/}
            </section>
          </article>
        </section>
        <section className="profile-info">
          <section>
            <Title className="--title">Profile Information</Title>
            <Table
              columns={columns}
              dataSource={getData(selectedUser)}
              showHeader={false}
              pagination={false}
              rowClassName="info-row --user-details"
            />
          </section>
          {/*<section className="general-stats">*/}
          {/*  <Title className="--title">General Stats</Title>*/}
          {/*  <section className="tiles-container">*/}
          {/*    {generalStats.map((data) => (*/}
          {/*      <UserTile {...data} />*/}
          {/*    ))}*/}
          {/*  </section>*/}
          {/*</section>*/}
          {/*<section className="task-breakdown">*/}
          {/*  <Title className="--title">Task</Title>*/}
          {/*  <section className="tiles-container">*/}
          {/*    {tasks.map((data) => (*/}
          {/*      <UserTile {...data} />*/}
          {/*    ))}*/}
          {/*  </section>*/}
          {/*</section>*/}
        </section>
        {/*<section className="updated-at">Last Updated 5 days ago</section>*/}
      </main>
    </>
  );
}
