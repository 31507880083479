import {useCallback, useEffect, useMemo, useState} from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppState } from "store";
import {Input, Table, TablePagination} from "components/base";
import {parseBatteryDetailTable, parseBatteryHeartbeatTable, parseBatteryTable} from "utils/parsers";
import { TableProps } from "antd/lib/table";
import {Breadcrumb, Button, Typography} from "antd";
import {KeyValue} from "../../../types";
import { backIcon, breadcrumbIcon, settingsIcon2 } from "svgs";
import {useHistory} from "react-router-dom";
import {getVehicle} from "../../../store/actions";
import {TabNavs} from "../../index";
const { Text } = Typography;

const columns: TableProps<any>["columns"] = [
    {
        title: "SOC",
        dataIndex: "soc",
        width: 100,
        ellipsis: true,
    },
    {
        title: "Host VIN",
        dataIndex: "host_vin",
        width: 130,
        ellipsis: true,
    },
    {
        title: "Time",
        dataIndex: "insertion_time",
        width: 58,
        ellipsis: true,
    },

    {
        title: "Host Type",
        dataIndex: "operational_state",
        width: 50,
        ellipsis: true,
    },
    {
        title: "Event Type",
        dataIndex: "event_type",
        width: 40,
        ellipsis: true,
    },
];

const batteryAllDataColumns: TableProps<any>["columns"] = [
    {
        title: "Time",
        dataIndex: "created_at",
        width: 190,
        ellipsis: true,
    },
    {
        title: "Charging counter",
        dataIndex: "charger_counter",
        width: 150,
        ellipsis: true,
    },
    {
        title: "Voltage",
        dataIndex: "voltage",
        width: 130,
        ellipsis: true,
    },
    {
        title: "Current",
        dataIndex: "current",
        width: 130,
        ellipsis: true,
    },
    {
        title: "SOC",
        dataIndex: "soc",
        width: 130,
        ellipsis: true,
    },
    {
        title: "Temperature",
        dataIndex: "temperature",
        width: 130,
        ellipsis: true,
    },
    {
        title: "Cycles",
        dataIndex: "cycles",
        width: 130,
        ellipsis: true,
    },
    {
        title: "Heartbeat",
        dataIndex: "heartbeat",
        width: 170,
        ellipsis: true,
    },
    {
        title: "Mosfet Charging",
        dataIndex: "mosfet",
        width: 150,
        ellipsis: true,
    },
    {
        title: "Mosfet Discharging",
        dataIndex: "mosfet_discharging",
        width: 160,
        ellipsis: true,
    },
    {
        title: "Individual cell voltages",
        dataIndex: "individual_cell_voltages",
        width: 550,
        ellipsis: true,
    },
];


const mockBatteryData = [
    {
        time: new Date().toString(),
        charging_counter: "30V",
        voltage: "1A",
        current: "40.5%",
        soc: "33%",
        temperature: "30°C",
        cycles: "5H3.30V",
        heartbeat: "2L3.31V NC:20 NT:1",
        mosfet_charging: "30V",
        mosfet_discharging: "30V",
        individual_cell_voltages: "13V",
    },
    {
        time: new Date().toString(),
        charging_counter: "30V",
        voltage: "1A",
        current: "40.5%",
        soc: "33%",
        temperature: "30°C",
        cycles: "5H3.30V",
        heartbeat: "2L3.31V NC:20 NT:1",
        mosfet_charging: "30V",
        mosfet_discharging: "30V",
        individual_cell_voltages: "13V",
    },
    {
        time: new Date().toString(),
        charging_counter: "30V",
        voltage: "1A",
        current: "40.5%",
        soc: "33%",
        temperature: "30°C",
        cycles: "5H3.30V",
        heartbeat: "2L3.31V NC:20 NT:1",
        mosfet_charging: "30V",
        mosfet_discharging: "30V",
        individual_cell_voltages: "13V",
    }
];

const batteryColumns: TableProps<any>["columns"] = [
    {
        title: "label",
        dataIndex: "label",
        width: 180,
        ellipsis: true,
    },
    {
        title: "value",
        dataIndex: "value",
        render: (value: string, row: KeyValue, index: number) => {
            if (row.label) {
                return (
                    <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>{value}</span>
                    </span>
                );
            }
            return ValueCell(value, row, index);
        },
    },
];

function ValueCell(value: string, row: KeyValue, index: number) {
    switch (row.type) {
        case "color":
            return (
                <span
                    className="value-with-color"
                    style={
                        {
                            "--color-cell": value,
                        } as React.CSSProperties
                    }
                >
          {value}
        </span>
            );
        default:
            return value;
    }
}
export default function BatteryDetailTableView() {
    const dispatch = useDispatch();
    const {
        batteries,
        page,
        battery_heartbeat,
        batteryDetail,
        selectedBattery,
        filters: { listType },
    } = useSelector<AppState, AppState["batteries"]>((state) => state.batteries);

    const history = useHistory();
    const onClickBack = useCallback(() => {
        history.goBack();
    }, [history]);


    const {
        vehicles: { selectedVehicle },
    } = useSelector<AppState, AppState>((state) => state);

    // useEffect( () => {
    //     if (selectedBattery?.hostId != "" && selectedBattery?.hostId != "00000000-0000-0000-0000-000000000000") {
    //         dispatch(getVehicle(selectedBattery?.hostId || ""))
    //     }
    // },[])

    const batteryData = [
        { label: "ID:", value: selectedBattery?.batteryId || "" },
        { label: "Current Operational State:", value: selectedBattery?.operationalState },
        { label: "Current Host ID:", value: selectedBattery?.hostId}
    ]
    const data = useMemo(
        () => parseBatteryDetailTable(batteryDetail),
        [batteryDetail]
    );

    const data2 = useMemo(
        () => parseBatteryHeartbeatTable(battery_heartbeat),
        [battery_heartbeat]
    );
    const [selectedView, setSelectedView] = useState("Battery Events");
    return (
        <section className="__table-container" style={{ marginTop: "20px"}}>
            <div style={{ width: "50px" }}></div>
            <Breadcrumb className="vehicle-route" separator={breadcrumbIcon} style={{ marginLeft: "-15px"}}>
                <Breadcrumb.Item>
                    <Button type="link" className="vehicles-link" onClick={onClickBack}>
                        Battery
                    </Button>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Battery Detail</Breadcrumb.Item>
            </Breadcrumb>
            <Table
                title={() => "Battery Detail"}
                bordered={false}
                showHeader={false}
                customClasses="--simple-two-columns"
                columns={batteryColumns}
                dataSource={batteryData}
                pagination={false}
            />
            <header className="vehicle-issue-table" style={{ marginTop: "20px"}}>
                {/*<Text className="table-title" style={{ color: "#000000", fontWeight: "bold" }}> History of Battery </Text>*/}
            </header>
            <TabNavs
                selected={selectedView}
                onChange={(value) => setSelectedView(value)}
                options={[{ key: "Battery Events" }, { key: "Battery Detail Data" }]}
            />
            <Table
                columns={selectedView == "Battery Events" ? columns : batteryAllDataColumns}
                dataSource={selectedView == "Battery Events" ? data : data2}
                scroll={{ y: "70vh" }}
                pagination={false}
            />
        </section>
    );
}
