import { Typography, Button } from "antd";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppState } from "store";
import {deleteBattery, setModalName} from "store/actions";

const { Text, Title } = Typography;

export default function DeleteBatteryForm() {
    const { selectedBattery } = useSelector<AppState, AppState["batteries"]>(
        (state) => state.batteries
    );
    const dispatch = useDispatch();

    const onDelete = useCallback(() => {
        console.log("Selected Model ID : ", selectedBattery?.id)
        dispatch(
            deleteBattery({id:selectedBattery?.id || ""})
        )
        onCancel();
    }, [dispatch, selectedBattery?.id]);

    const onCancel = useCallback(() => {
        dispatch(setModalName(undefined));
    }, [dispatch]);

    return (
        <section className="delete-modal">
            <Title level={5}>Archive Battery</Title>
            <Text type="secondary">
                Are you sure you want to archive {" "}
                <strong>
                    “{selectedBattery?.batteryId}”
                </strong>{" "}
                ?
            </Text>
            <div className="action-btn-container">
                <Button className="cancel-btn" onClick={onCancel}>
                    Cancel
                </Button>
                <Button type="primary" danger onClick={onDelete}>
                    Archive
                </Button>
            </div>
        </section>
    );
}
