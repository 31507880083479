import { useMemo, useCallback } from "react";
import { Menu, Dropdown } from "antd";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ROUTES } from "utils/constants";
import { actionMenu } from "svgs";
import { IssuesState } from "types/store";
import { setModalName, setSelectedIssue } from "store/actions";

export default function DropdownAction({
  issue,
}: {
  issue: IssuesState["selectedIssue"];
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const onIssueSelected = () => {
    dispatch(setSelectedIssue(issue));
    history.push(ROUTES.issue_detail);
  };
  const onArchiveIssue = useCallback(() => {
    dispatch(setSelectedIssue(issue));
    dispatch(setModalName("delete_issue"));
  }, [dispatch, issue]);

  const menu = useMemo(
    () => (
      <Menu>
        <Menu.Item key="1">
          <span onClick={onIssueSelected}>
            Issue Detail
          </span>
        </Menu.Item>
        <Menu.Item key="2" className="delete-action" onClick={onArchiveIssue}>
          Archive
        </Menu.Item>
      </Menu>
    ),
    [issue, onArchiveIssue]
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <a className="ant-dropdown-link">{actionMenu}</a>
    </Dropdown>
  );
}
